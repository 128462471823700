import i18next from 'i18next'
import de from './resources/de'
import en from './resources/en'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'

i18next
    .use(detector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['querystring', 'cookie', 'navigator'],
        },
        debug: (import.meta as any).env.REACT_APP_ENV !== 'prod',
        fallbackLng: 'en',
        defaultNS: 'translation',
        ns: ['translation'],
        resources: {
            de: {
                translation: de,
            },
            en: {
                translation: en,
            },
        },
    })

document.documentElement.lang = i18next.language

export default i18next
