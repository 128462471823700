import React from 'react'
import T from '../../../../../components/typography/t'
import styled from '@mui/material/styles/styled'
import HappyIllustration from './repair-thank-you.jpg'

const PageContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`

const Outro = () => {
    return (
        <PageContainer>
            <img src={HappyIllustration} style={{ maxWidth: '90%', width: '400px' }} />
            <T
                i18nKey="poc.support.repairOutroStep.title"
                variant="h4"
                component="h1"
                mt={4}
                mb={1}
                color={'primary'}
            />
            <T
                i18nKey="poc.support.repairOutroStep.subtitle"
                variant="body1"
                component="p"
                align="center"
                mx={2}
            />
        </PageContainer>
    )
}

export default Outro
