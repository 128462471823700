import React from 'react'
import { WidthContainer } from '../layout'
import { useTheme, Typography } from '@mui/material'
import HappyIllustration from './happy-illustration'

const GuideOutroPage = React.forwardRef<HTMLDivElement>((_, ref) => {
    const theme = useTheme()
    return (
        <WidthContainer
            sx={{ display: 'flex', flexDirection: 'column', height: 'auto', alignItems: 'center' }}
            ref={ref}
        >
            <HappyIllustration theme={theme} />

            <Typography variant="h5" component="h1" fontSize={16} fontWeight="600" mb={1}>
                Enjoy your newly repaired product!
            </Typography>
        </WidthContainer>
    )
})

export default GuideOutroPage
