import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import ListItemButton from '@mui/material/ListItemButton'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import PublicProduct from '../../../../../shared/types/product/public-product'
import createUploadcareImageSrc from '../../../../mixins/create-uploadcare-image-src'
import styled from 'styled-components'

const ListEntryImagePreview = styled.img`
    width: 64px;
    height: 64px;
    object-fit: contain;
    margin-right: 1rem;
`

const CompanyProductList = ({ products }: { products: PublicProduct[] }) => {
    const source = new URLSearchParams(window.location.search).get('source') ?? 'other'

    return (
        <Box my={2} width={'100%'} maxWidth={'500px'}>
            {products.map((product, index) => (
                <Card key={product.id} sx={{ width: '100%', marginBottom: '1rem' }}>
                    <ListItem sx={{ padding: 0 }}>
                        <ListItemButton href={`${product.questionaireLink}?source=${source}`}>
                            <ListItemAvatar>
                                <ListEntryImagePreview
                                    src={createUploadcareImageSrc(product.imgUrl, {
                                        preview: '128x128',
                                    })}
                                />
                            </ListItemAvatar>
                            <ListItemText>{product.name}</ListItemText>
                            <ListItemIcon>
                                <ArrowForwardIcon color="primary" />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                </Card>
            ))}
        </Box>
    )
}

export default CompanyProductList
