import React from 'react'
import ProductDashboardT from '../../../../shared/types/product/product-dashboard'
import SubmissionId from '../../../../shared/types/submission/id'
import { useTranslation } from 'react-i18next'
import SubmissionDetail from './submission-detail/index'
import DesktopDashboard from '../inspector/product-dashboard/overview/desktop/desktop-dashboard'
import { useIsMobile } from '../../../mixins/media-query'
import MobileDashboard from '../inspector/product-dashboard/overview/mobile/mobile-dashboard'
import useUrlQuery from '../../../mixins/use-url-query'
import Filterbar, { FormData } from './filterbar'
import { FilterLabel } from '../../../../shared/types/submission/label'
import { OriginFilter } from '../../../../shared/types/submission/origin-filter'
import InspectionOrigin from '../../../../shared/inspection/inspection-origin'
import routeBuilder from '../../../routes'
import SubmissionDialogWrapper from '../inspector/product-dashboard/overview/submission-dialog-wrapper'
import useAuth from '../../../mixins/use-auth'
import { deleteSubmission } from 'wasp/client/operations'

const SubmissionsViewer = ({
    productDashboard,
    markSubmissionAsSeen,
}: {
    productDashboard: ProductDashboardT
    markSubmissionAsSeen: ({ submissionId }: { submissionId: SubmissionId }) => void
}) => {
    const { isAdmin } = useAuth()
    const isMobile = useIsMobile()

    const [filterLabelFromUrl, setFilterLabelInUrl] = useUrlQuery('labels')
    const [filterOriginFromUrl, setFilterOriginInUrl] = useUrlQuery('origins')
    const parsedFilterLabelFromUrl = (filterLabelFromUrl()?.split(',') ?? []).map((l) =>
        parseInt(l)
    )
    const parsedFilterOriginFromUrl = (filterOriginFromUrl()?.split(',') ?? []).map((o) =>
        parseInt(o)
    )
    const [selectedLabelFilters, setSelectedLabelFilters] =
        React.useState<number[]>(parsedFilterLabelFromUrl)
    const [selectedOriginFilters, setSelectedOriginFilters] =
        React.useState<number[]>(parsedFilterOriginFromUrl)

    const setLabelFilter = (label: FilterLabel) => {
        if (selectedLabelFilters.find((l) => l === label.id)) {
            setSelectedLabelFilters((prev) => prev.filter((l) => l !== label.id))
            setFilterLabelInUrl(selectedLabelFilters.filter((l) => l !== label.id).join(','))
        } else {
            setSelectedLabelFilters((prev) => [...prev, label.id])
            setFilterLabelInUrl([...selectedLabelFilters, label.id].join(','))
        }
    }

    const setOriginFilter = (origin: OriginFilter) => {
        if (selectedOriginFilters.find((l) => l === origin.id)) {
            setSelectedOriginFilters((prev) => prev.filter((l) => l !== origin.id))
            setFilterOriginInUrl(selectedOriginFilters.filter((l) => l !== origin.id).join(','))
        } else {
            setSelectedOriginFilters((prev) => [...prev, origin.id])
            setFilterOriginInUrl([...selectedOriginFilters, origin.id].join(','))
        }
    }

    const [t] = useTranslation()

    const onOpenSubmission = (submissionId: SubmissionId) => {
        const submission = productDashboard.submissions.find((sub) => sub.id === submissionId)
        if (submission?.unseenByUser) {
            markSubmissionAsSeen({ submissionId })
        }
    }

    const filterFn = ({ searchTerm, showOnlyUnseen }: FormData) => {
        return productDashboard.submissions
            .filter((sub) => {
                if (searchTerm === '') {
                    return showOnlyUnseen ? sub.unseenByUser : true
                } else {
                    return (
                        (sub.email?.toLocaleLowerCase().includes(searchTerm) ||
                            sub.contextDescription.toLocaleLowerCase().includes(searchTerm) ||
                            sub.description.toLocaleLowerCase().includes(searchTerm) ||
                            sub.createdAt.toLocaleLowerCase().includes(searchTerm)) &&
                        (showOnlyUnseen ? sub.unseenByUser : true)
                    )
                }
            })
            .filter((sub) => {
                if (selectedLabelFilters.length === 0) {
                    return true
                }
                return selectedLabelFilters.every((labelId) =>
                    sub.labels.some((l) => l.id === labelId)
                )
            })
            .filter((sub) => {
                if (selectedOriginFilters.length === 0) {
                    return true
                }
                return selectedOriginFilters.includes(sub.inspectionOrigin)
            })
    }

    const toOriginObject = (inspectionOrigin: InspectionOrigin) => ({
        id: inspectionOrigin,
        value: inspectionOrigin === InspectionOrigin.Feedback ? 'Feedback' : 'Repair',
    })

    return (
        <>
            <Filterbar
                filterFn={filterFn}
                getLabelsFromEntry={(entry) => entry.labels}
                getOriginsFromEntry={(entry) => [toOriginObject(entry.inspectionOrigin)]}
                selectedLabelIds={selectedLabelFilters}
                selectedOriginIds={selectedOriginFilters}
                backButtonUrl={routeBuilder.inspectorApp(
                    isAdmin ? productDashboard.companyId : undefined
                )}
                allLabels={productDashboard.submissions.map((sub) => sub.labels).flat()}
                allOrigins={productDashboard.submissions.map((sub) =>
                    toOriginObject(sub.inspectionOrigin)
                )}
            >
                {(filteredSubmissions, filteredLabels, filteredOrigins) => (
                    <SubmissionDialogWrapper onOpenSubmission={onOpenSubmission}>
                        {({ openedSubmissionId, setOpenedSubmissionId }) => (
                            <>
                                {isMobile ? (
                                    <MobileDashboard
                                        productImgUrl={productDashboard.productImgUrl}
                                        submissions={filteredSubmissions}
                                        openSubmission={setOpenedSubmissionId}
                                    />
                                ) : (
                                    <DesktopDashboard
                                        productImgUrl={productDashboard.productImgUrl}
                                        submissions={filteredSubmissions}
                                        openSubmission={setOpenedSubmissionId}
                                        filterLabels={filteredLabels}
                                        filterOrigins={filteredOrigins}
                                        setLabelFilter={setLabelFilter}
                                        setOriginFilter={setOriginFilter}
                                    />
                                )}

                                {!!openedSubmissionId && (
                                    <SubmissionDetail
                                        submissions={filteredSubmissions.map((sub) => ({
                                            ...sub,
                                            productId: productDashboard.id,
                                            productImgUrl: productDashboard.productImgUrl,
                                            productName: productDashboard.name,
                                        }))}
                                        openedSubmissionId={openedSubmissionId}
                                        setOpenedSubmissionId={setOpenedSubmissionId}
                                        close={() => setOpenedSubmissionId(undefined)}
                                        productImgUrl={productDashboard.productImgUrl}
                                        deleteSubmission={async (id) => {
                                            await deleteSubmission({ submissionId: id })
                                            setOpenedSubmissionId(undefined)
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </SubmissionDialogWrapper>
                )}
            </Filterbar>
        </>
    )
}

export default SubmissionsViewer
