import CookieConsent from 'react-cookie-consent'
import theme from '../../theme'
import useCookie from '../../mixins/use-cookie'
import { useIsMobile } from '../../mixins/media-query'
import React from 'react'
import { Backdrop } from '@mui/material'

const cookieName = 'brakeable-cookie-consent'

export default ({ executeIfCookiesAccepted }: { executeIfCookiesAccepted: () => void }) => {
    const isMobile = useIsMobile()
    const [getCookieBannerCookie] = useCookie(cookieName)

    const defaultBannerStyle = {
        background: '#000',
        color: '#fff',
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        padding: '15px',
        zIndex: 1100,
    }
    const defaultButtonStyle = {
        color: '#000',
        backgroundColor: '#fff',
        margin: 0,
        fontSize: '12px',
        borderRadius: '4px',
        textTransform: 'uppercase',
        padding: '8px 22px',
        marginTop: '10px',
    }
    const mobileBannerStyle = {
        flexDirection: 'column',
        margin: '45px 30px',
        width: 'calc(100% - 60px)',
        padding: '10px',
        textAlign: 'center',
        boxShadow: '-2px 2px 2px 0px rgba(0,0,0,0.25)',
        borderRadius: '4px',
    }
    const mobileButtonStyle = {
        color: '#000',
        backgroundColor: '#fff',
    }
    const bannerStyle = isMobile
        ? { ...defaultBannerStyle, ...mobileBannerStyle }
        : defaultBannerStyle

    const buttonStyle = isMobile
        ? { ...defaultButtonStyle, ...mobileButtonStyle }
        : defaultButtonStyle

    const [isOpen, setOpen] = React.useState(!getCookieBannerCookie())

    const onAccept = () => {
        executeIfCookiesAccepted()
        setOpen(false)
    }

    React.useEffect(() => {
        if (getCookieBannerCookie()) {
            const timeoutId = setTimeout(executeIfCookiesAccepted, 1000)
            return () => clearTimeout(timeoutId)
        }
    }, [executeIfCookiesAccepted])

    return (
        <Backdrop open={isOpen} sx={{ zIndex: 2 }}>
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName={cookieName}
                onAccept={onAccept}
                style={bannerStyle as any}
                buttonStyle={buttonStyle as any}
                contentStyle={{ margin: 0, flexBasis: 'auto' }}
            >
                We use cookies to ensure that we give you the best experience on our website. If you
                continue to use this site we assume that you are happy with it.
            </CookieConsent>
        </Backdrop>
    )
}
