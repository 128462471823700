import React from 'react'
import TextField from '@mui/material/TextField'
import { SetupMultiStepFormContext } from '../setup-multi-step-form'
import InspectorStepTitle from '../../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from './components/step-framework-wrapper'
import InspectorStepSubtitle from '../../../../../../components/typography/inspector-step-subtitle'
import { useTranslation } from 'react-i18next'

export const InfoStep = ({
    onProductNameChange,
    initialDescription,
}: {
    onProductNameChange: (description: string) => void
    initialDescription?: string
}) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle
                i18nKey="company.inspector.home.products.newProduct.form.infoStep.title"
                Icon={undefined}
            />
            <InspectorStepSubtitle
                mb={4}
                i18nKey="company.inspector.home.products.newProduct.form.infoStep.subtitle"
            />
            <TextField
                defaultValue={initialDescription}
                label={t('company.inspector.home.products.newProduct.form.infoStep.label' as any)}
                placeholder={t(
                    'company.inspector.home.products.newProduct.form.infoStep.placeholder' as any
                )}
                sx={{ width: '300px' }}
                onChange={(e) => onProductNameChange(e.target.value)}
            ></TextField>
        </>
    )
}

const InfoStepContext = () => {
    const { form } = React.useContext(SetupMultiStepFormContext)
    return (
        <StepFrameworkWrapper
            navigationProps={{
                nextButtonDisabled: !form.formValues.productName,
            }}
        >
            <InfoStep
                onProductNameChange={form.setProductName}
                initialDescription={form.formValues.productName}
            />
        </StepFrameworkWrapper>
    )
}

export default InfoStepContext
