import React from 'react'
import Coord from '../../../../../../../shared/types/common/coord'
import { SupportMultiStepFormContext } from '../../context'
import Pinpoint from './pinpoint'
import InspectorStepTitle from '../../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../components/step-framework-wrapper'
import InspectorStepSubtitle from '../../../../../../components/typography/inspector-step-subtitle'
import createUploadcareImageSrc from '../../../../../../mixins/create-uploadcare-image-src'
import { ProductDetail, ProductTypeId, ProductView } from '../../data/products/types'
import { findProductTypeById } from '../../data/products/helpers'
import { Tab, Tabs, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ImageWrapperAligner = styled('div')`
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-height: 100%;
    flex-shrink: 1;
    min-height: 0; // too shrink element to fit image inside flexbox
    flex-grow: 1;
    width: 100%;
`

export const PinpointStep = ({
    productViews,
    setInspectionImagePinpoint,
    setSelectedComponent,
    initialCoords,
    initialSelectedProductView,
    setSelectedProductView,
}: {
    productViews: ProductView[]
    setInspectionImagePinpoint: (pinpoint?: Coord) => void
    setSelectedComponent: (component?: string) => void
    setSelectedProductView: (view: number) => void
    initialCoords?: Coord
    initialSelectedProductView: number
}) => {
    const [selectedProductView, setSelectedProductViewState] = React.useState(
        initialSelectedProductView
    )
    const [t] = useTranslation()

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedProductViewState(newValue)
    }

    const setPinpoint = (pinpoint: Coord) => {
        setInspectionImagePinpoint(pinpoint)
        setSelectedProductView(selectedProductView)
    }
    const productView = productViews[selectedProductView]

    return (
        <>
            <InspectorStepTitle i18nKey="support.mobile.pinpoint.title" mb={2} />
            {productViews.length > 1 && (
                <Tabs
                    value={selectedProductView}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                >
                    {productViews.map((view) => (
                        <Tab
                            key={view.label}
                            label={t(view.label as any)}
                            id={`product-view-tabs-${view.label}`}
                            aria-controls={`productview-tabpanel-${view.label}`}
                        />
                    ))}
                </Tabs>
            )}
            <ImageWrapperAligner>
                <Pinpoint
                    key={productView.label}
                    productImageUrl={createUploadcareImageSrc(productView.sketchUrl, {
                        preview: '700x1400',
                    })}
                    onPinpointChanged={setPinpoint}
                    setSelectedComponent={setSelectedComponent}
                    initialCoords={
                        initialSelectedProductView === selectedProductView
                            ? initialCoords
                            : undefined
                    }
                    productDetails={productView.details}
                />
            </ImageWrapperAligner>
        </>
    )
}

const PinpointStepContext = () => {
    const { ctx, form, props } = React.useContext(SupportMultiStepFormContext)

    const portrait = window.matchMedia('(orientation: portrait)')
    const [isInPortraitMode, setIsInPortraitMode] = React.useState<boolean>(portrait.matches)

    const orientationChanged = (e: MediaQueryListEvent) => {
        setIsInPortraitMode(e.matches)
    }

    React.useEffect(() => {
        portrait.addEventListener('change', orientationChanged)

        return () => {
            portrait.removeEventListener('change', orientationChanged)
        }
    }, [])

    const productNode = findProductTypeById(
        (form.formValues.selectedProduct ?? (-1 as any)) as ProductTypeId,
        ctx.productCategoryTree
    )

    const productViews =
        (productNode?.views.length ?? 0) > 0
            ? productNode!.views
            : [
                  {
                      label: 'undefined',
                      sketchUrl: 'https://ucarecdn.com/46188606-f794-455e-889f-3dc712aac059/',
                      details: [],
                  },
              ]

    return (
        <StepFrameworkWrapper
            squeezeInScreen={true}
            navigationProps={{
                nextButtonDisabled: !form.formValues.pinpoint,
                onValidPrevClick: () => {
                    form.setSelectedProductView(0)
                    form.setPinpoint(undefined)
                    props.prevSlide()
                },
            }}
        >
            <PinpointStep
                setInspectionImagePinpoint={form.setPinpoint}
                setSelectedComponent={form.setSelectedComponent}
                setSelectedProductView={form.setSelectedProductView}
                productViews={productViews}
                initialCoords={form.formValues.pinpoint}
                initialSelectedProductView={form.formValues.selectedProductView}
            />
        </StepFrameworkWrapper>
    )
}

export default PinpointStepContext
