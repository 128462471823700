import { UploadClient } from '@uploadcare/upload-client'
import Coord from '../../../../../shared/types/common/coord'
import supportServices from './data/support-services'
import { RepairGuide } from '../../../../../shared/data/repair-guides/types'
import React from 'react'
import { Whitelabel, brkWhitelabel } from './data/whitelabel'
import { ProductCategoryNode } from './data/products/types'
import defaultProductCategories from './data/products'

export type StepCtx = {
    supportServices: {
        id: number
        title: string
        description: string
        productCategory: number
        price: number
    }[]
    repairGuides: RepairGuide[]
    productCategoryTree: ProductCategoryNode
    isWhiteLabelled: boolean
    whitelabel: Whitelabel
    pinpointImage: string
    addDIYStep: () => void
    addNoWarrantySteps: () => void
    removeDIYStep: () => void
    removeNoWarrantySteps: () => void
    getRepairGuideSuggestions: () => void
    getFollowUpQuestion: () => void
}

export type StepProps = {
    currentStep: number
    maxSteps: number
    setSlide: (index: number) => void
    nextSlide: () => void
    prevSlide: () => void
    gotoEnd: () => void
}

export type Form = {
    setCustomerEmail: (email: string) => void
    setSelectedProductCategory: (categoryId: number) => void
    setSelectedProduct: (productId?: number) => void
    setSelectedProductView: (productViewId: number) => void
    setSelectedServices: (serviceIds: number[]) => void
    setAiSelectedServices: (serviceIds: number[]) => void
    setAiSelectedRepairGuides: (guideIds: number[]) => void
    setAiRelatedServices: (serviceIds: number[]) => void
    setDescription: (description: string) => void
    setUploadedPhotoUrl: (url?: string) => void
    setPinpoint: (coords?: Coord) => void
    setClaimProbability: (probability: number) => void
    setAIFollowUpQuestion: (question: string) => void
    setFollowUpAnswer: (answer: string) => void
    setSelectedComponent: (component?: string) => void
    formValues: FormValues
    submitForm: () => Promise<void>
    resetForm: () => void
}

export type FormValues = {
    customerEmail: string
    selectedProductCategory?: number
    selectedProduct?: number
    selectedProductView: number
    selectedServices: number[]
    aiSelectedServices: number[]
    aiSelectedRepairGuides: number[]
    aiRelatedServices: number[]
    description?: string
    uploadedPhotoUrl?: string
    pinpoint?: Coord
    claimProbability?: number
    followUpQuestion?: string
    followUpAnswer?: string
    selectedComponent?: string
}

export type Meta = {
    value?: any
    setMetaValue: (value: any) => void
}

export type MultiStepFormError = {
    id?: string
    message: string
    action?: {
        label: string
        onClick: () => void
    }
}

export type ErrorCtx = {
    pushError: (error: MultiStepFormError) => void
    popError: () => void
    errors: MultiStepFormError[]
}

export type SupportMultiStepFormContextType = {
    ctx: StepCtx
    props: StepProps
    form: Form
    meta: Meta
    error: ErrorCtx
    uploadCareClient: UploadClient
}
export const defaultContextValues: SupportMultiStepFormContextType = {
    ctx: {
        supportServices: supportServices,
        productCategoryTree: defaultProductCategories,
        repairGuides: [],
        isWhiteLabelled: false,
        addDIYStep: () => {},
        removeDIYStep: () => {},
        addNoWarrantySteps: () => {},
        removeNoWarrantySteps: () => {},
        getRepairGuideSuggestions: () => {},
        getFollowUpQuestion: () => {},
        pinpointImage: 'https://ucarecdn.com/32f35fab-3409-4559-8434-17843d0b7dbc/',
        whitelabel: brkWhitelabel,
    },
    props: {} as StepProps,
    form: {} as Form,
    meta: {} as Meta,
    error: {} as ErrorCtx,
    uploadCareClient: {} as UploadClient,
}

export const SupportMultiStepFormContext =
    React.createContext<SupportMultiStepFormContextType>(defaultContextValues)
