import React from 'react'
import RepairForm from './multi-step/repair'
import WarrantyForm from './multi-step/warranty'
import DIYForm from './multi-step/diy'
import FeedbackForm from './multi-step/feedback'
import uploadcareClient from '../../../mixins/uploadcare-client'
import { CopilotKit } from '@copilotkit/react-core'
import { copilotKitEndpoint } from '../../../routes'
import '@copilotkit/react-ui/styles.css'
import Linktree from './linktree'
import { ThemeProvider, createTheme } from '@mui/material'
import useUrlQuery from '../../../mixins/use-url-query'
import whitelabels, { Whitelabel, brkWhitelabel } from './multi-step/data/whitelabel'
import { useAnalytics } from 'use-analytics'
import { POCPageViewEvent, POCSubmissionEvent } from '../../../../shared/analytics'
import { useIsMobile } from '../../../mixins/media-query'

const SupportFormPage = () => {
    const [getCompanyBrand] = useUrlQuery('cb')
    const companyBrand = getCompanyBrand()
    const whitelabel: Whitelabel = whitelabels.find((w) => w.id === companyBrand) ?? brkWhitelabel
    return <SupportForn whitelabel={whitelabel} />
}

const createBrandedProdDemo = (brandId: string, disabledMainFlows: number[]) => () => {
    const analytics = useAnalytics()
    const isMobile = useIsMobile()

    const whitelabel: Whitelabel = whitelabels.find((w) => w.id === brandId) ?? brkWhitelabel

    React.useEffect(() => {
        analytics.track(POCPageViewEvent, { isMobile, brandId: brandId })
    }, [])

    const onSubmit = async (data: any) => {
        analytics.track(POCSubmissionEvent, { isMobile, ...data, brandId: brandId })
    }

    return (
        <SupportForn
            whitelabel={{
                ...whitelabel,
                disabledMainFlows: disabledMainFlows,
                hideFakeFeatures: true,
            }}
            onSubmit={onSubmit}
        />
    )
}

export const DemoSupportFormPage = createBrandedProdDemo('1', [2])
export const IntersportSupportFormPage = createBrandedProdDemo('43574', [1, 2, 3])
export const VictorinoxSupportFormPage = createBrandedProdDemo('241200', [1, 2, 3])

const SupportForn: React.FC<{ whitelabel: Whitelabel; onSubmit?: (d: any) => Promise<void> }> = ({
    whitelabel,
    onSubmit = async (...params) => console.log(...params),
}) => {
    const [type, setType] = React.useState(0)

    return (
        <ThemeProvider
            theme={(theme: any) =>
                createTheme({
                    ...theme,
                    palette: {
                        ...theme.palette,
                        ...whitelabel?.theme.palette,
                    },
                } as any)
            }
        >
            <CopilotKit runtimeUrl={copilotKitEndpoint}>
                {type === 0 && <Linktree setType={setType} whitelabel={whitelabel} />}
                {type === 1 && (
                    <RepairForm
                        whitelabel={whitelabel}
                        onSubmit={onSubmit}
                        uploadCareClient={uploadcareClient}
                        goHome={() => setType(0)}
                    />
                )}
                {type === 2 && (
                    <WarrantyForm
                        whitelabel={whitelabel}
                        onSubmit={onSubmit}
                        uploadCareClient={uploadcareClient}
                        goHome={() => setType(0)}
                    />
                )}
                {type === 3 && (
                    <FeedbackForm
                        whitelabel={whitelabel}
                        onSubmit={onSubmit}
                        uploadCareClient={uploadcareClient}
                        goHome={() => setType(0)}
                    />
                )}
                {type === 4 && (
                    <DIYForm
                        whitelabel={whitelabel}
                        onSubmit={onSubmit}
                        uploadCareClient={uploadcareClient}
                        goHome={() => setType(0)}
                    />
                )}
            </CopilotKit>
        </ThemeProvider>
    )
}

export default SupportFormPage
