import { logout } from 'wasp/client/auth'
import React from 'react'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import PageWrapper from '../../../components/layout/page-wrapper'
import T from '../../../components/typography/t'
import CompanyId from '../../../../shared/types/company/id'
import CompanySettingsWrapper from './company-settings-wrapper'
import nullableParseInt from '../../../mixins/nullable-parse-int'
import LanguageSwitcher from '../../../components/language-switcher'

const SettingsPage = ({ match }: any) => {
    const { companyId } = match.params
    const compId = nullableParseInt(companyId) as CompanyId | undefined
    return (
        <PageWrapper companyId={compId}>
            <CompanySettingsWrapper companyId={compId} />
            <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                sx={{ width: '100%', mt: 4 }}
            >
                <Button onClick={logout}>
                    <T i18nKey="common.logout" />
                </Button>
                <LanguageSwitcher />
            </Stack>
        </PageWrapper>
    )
}

export default SettingsPage
