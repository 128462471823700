import React from 'react'
import Stack from '@mui/material/Stack'
import PageWrapper from '../../../components/layout/page-wrapper'
import CompanyId from '../../../../shared/types/company/id'
import ContactRequestsWrapper from './contact-requests/contact-requests-wrapper'
import AppSuite from '../../../../shared/app-suite'
import nullableParseInt from '../../../mixins/nullable-parse-int'

const UserManagementPage = ({ match }: any) => {
    const { companyId } = match.params
    const compId = nullableParseInt(companyId) as CompanyId | undefined
    return (
        <PageWrapper activeLogo={AppSuite.Support} companyId={compId}>
            <Stack spacing={3} sx={{ width: '100%' }}>
                <ContactRequestsWrapper companyId={compId} />
            </Stack>
        </PageWrapper>
    )
}

export default UserManagementPage
