export default [
    {
        id: 56143,
        url: 'https://www.ifixit.com/Guide/How+do+I+replace+the+wheels+on+my+Tobago+trolley-/56143',
        title: 'How do I replace the wheels on my Tobago trolley?',
        summary: 'Our trolleys are designed for a long lifespan....',
        image: 'https://guide-images.cdn.ifixit.com/igi/PNJhwXsKMWGn5aYA.full',
        brandId: '122278',
    },
    {
        id: 56151,
        url: 'https://www.ifixit.com/Guide/How+do+I+replace+the+wheels+on+my+Melbourne+trolley-/56151',
        title: 'How do I replace the wheels on my Melbourne trolley?',
        summary: 'Whether over asphalt, marble floors or...',
        image: 'https://guide-images.cdn.ifixit.com/igi/32qfanSN2GByWGBO.full',
        brandId: '122278',
    },
    {
        id: 67698,
        url: 'https://www.ifixit.com/Guide/How+do+I+remove+pilling+from+my+Vaude+pullover-/67698',
        title: 'How do I remove pilling from my Vaude pullover?',
        summary: 'Some types of fabrics (i.e. wool) or areas that...',
        image: 'https://guide-images.cdn.ifixit.com/igi/vUIWxPQhbfVDXOn2.full',
        brandId: '122278',
    },
    {
        id: 67699,
        url: 'https://www.ifixit.com/Guide/How+do+I+replace+the+thread+plate+on+my+bike+bag-/67699',
        title: 'How do I replace the thread plate on my bike bag?',
        summary: 'QMR hooks are attached to the hardback panel...',
        image: 'https://guide-images.cdn.ifixit.com/igi/pVQROYy4ksBaGXnC.full',
        brandId: '122278',
    },
    {
        id: 69098,
        url: 'https://www.ifixit.com/Guide/How+do+I+replace+the+Klickfix+mounting+plate+on+my+Aqua+Box-/69098',
        title: 'How do I replace the Klickfix mounting plate on my Aqua Box?',
        summary: 'After years of use, you might need to replace...',
        image: 'https://guide-images.cdn.ifixit.com/igi/2ECAFTUWmMRByULW.full',
        brandId: '122278',
    },
    {
        id: 69099,
        url: 'https://inspector.brakeable.com/ufixit/69099',
        title: 'How do I repair taped seams on my Vaude jacket?',
        summary: 'Taped seams can occasionally separate from a...',
        image: 'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.full',
        brandId: '122278',
    },
    {
        id: 69100,
        url: 'https://www.ifixit.com/Guide/How+can+I+seal+the+seam+stitching+on+my+ultralight+tent-/69100',
        title: 'How can I seal the seam stitching on my ultralight tent?',
        summary: 'VAUDE ultralight tents have an expanded...',
        image: 'https://guide-images.cdn.ifixit.com/igi/kaZJqFMROV2nRoqI.full',
        brandId: '122278',
    },
    {
        id: 69101,
        url: 'https://inspector.brakeable.com/ufixit/69101',
        title: 'How do I replace a cord stopper on my Vaude jacket drawstring?',
        summary: 'Occasionally, a cord stopper can come loose...',
        image: 'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.full',
        brandId: '122278',
    },
    {
        id: 69102,
        url: 'https://inspector.brakeable.com/ufixit/69102',
        title: 'How do I replace a broken zip slider on my Vaude clothing?',
        summary: 'VAUDE uses premium zips from YKK and other...',
        image: 'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.full',
        brandId: '122278',
    },
    {
        id: 69103,
        url: 'https://www.ifixit.com/Guide/How+to+replace+the+extension+handle+on+my+trolley-/69103',
        title: 'How to replace the extension handle on my trolley?',
        summary: 'You can use these instructions for almost all...',
        image: 'https://guide-images.cdn.ifixit.com/igi/bBTLYeSZWyjfQVZP.full',
        brandId: '122278',
    },
    {
        id: 69105,
        url: 'https://inspector.brakeable.com/ufixit/69105',
        title: 'How do I lubricate a sticky zip on my Vaude clothing?',
        summary: 'Zips often become harder to open or close. Most...',
        image: 'https://guide-images.cdn.ifixit.com/igi/6draOBRknNP2fuNG.full',
        brandId: '122278',
    },
    {
        id: 69108,
        url: 'https://www.ifixit.com/Guide/How+do+I+replace+a+QMR+Rail+Hook+on+the+hardback+panel+of+my+bike+bag-/69108',
        title: 'How do I replace a QMR Rail Hook on the hardback panel of my bike bag?',
        summary: 'A QMR Rail Hook on your hardback panel...',
        image: 'https://guide-images.cdn.ifixit.com/igi/cB2OHa3C4WYZuHAO.full',
        brandId: '122278',
    },
    {
        id: 69111,
        url: 'https://inspector.brakeable.com/ufixit/69111',
        title: 'How do I replace a broken buckle on my backpack?',
        summary: 'If the hook end (male end) of your buckle is...',
        image: 'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.full',
        brandId: '122278',
    },
    {
        id: 69115,
        url: 'https://inspector.brakeable.com/ufixit/69115',
        title: 'How do I replace a broken zip on my Vaude jacket?',
        summary: 'If the zip on your jacket breaks, sometimes the...',
        image: 'https://guide-images.cdn.ifixit.com/igi/HnFokidbJhDKAVMV.full',
        brandId: '122278',
    },
    {
        id: 69120,
        url: 'https://inspector.brakeable.com/ufixit/69120',
        title: 'How do I replace a sternum strap on my backpack?',
        summary: 'If the sternum strap comes off of its rail or...',
        image: 'https://guide-images.cdn.ifixit.com/igi/3CWd5ZwgV4rkrfyM.full',
        brandId: '122278',
    },
    {
        id: 69122,
        url: 'https://www.ifixit.com/Guide/How+do+I+replace+a+broken+segment+of+my+tent+pole-/69122',
        title: 'How do I replace a broken segment of my tent pole?',
        summary: 'Our tents are constructed to stand up to...',
        image: 'https://guide-images.cdn.ifixit.com/igi/M5IFySdZ2xSKqtrU.full',
        brandId: '122278',
    },
    {
        id: 69137,
        url: 'https://inspector.brakeable.com/ufixit/69137',
        title: 'How do I repair a two-way zip slider on my Vaude jacket on a split zip?',
        summary: 'A two-way zip (two sliders for opening the zip...',
        image: 'https://guide-images.cdn.ifixit.com/igi/YUnbHtRArnHNQ2KY.full',
        brandId: '122278',
    },
    {
        id: 69138,
        url: 'https://inspector.brakeable.com/ufixit/69138',
        title: 'How can I resew a loose Velcro on a Vaude jacket?',
        summary: 'If a seam on your Velcro (i.e. on the flap of...',
        image: 'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.full',
        brandId: '122278',
    },
    {
        id: 69140,
        url: 'https://www.ifixit.com/Guide/When+do+I+need+to+re-waterproof+my+Vaude+garment-/69140',
        title: 'When do I need to re-waterproof my Vaude garment?',
        summary: 'When water no longer beads up on the outside of...',
        image: 'https://guide-images.cdn.ifixit.com/igi/WyUIZt1dlCJ1QqAO.full',
        brandId: '122278',
    },
    {
        id: 69141,
        url: 'https://inspector.brakeable.com/ufixit/69141',
        title: 'What’s the right way to wash and dry my Vaude down jacket?',
        summary: 'Thermal appeal with down fill has to be treated...',
        image: 'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.full',
        brandId: '122278',
    },
    {
        id: 69144,
        url: 'https://www.ifixit.com/Guide/How+do+I+wash+my+Vaude+wool+product-/69144',
        title: 'How do I wash my Vaude wool product?',
        summary: 'Wool is a natural fiber that is similar to...',
        image: 'https://guide-images.cdn.ifixit.com/igi/XMU1l5kdrDjQuBbK.full',
        brandId: '122278',
    },
    {
        id: 69150,
        url: 'https://inspector.brakeable.com/ufixit/69150',
        title: 'How do I wash and dry my Vaude membrane jacket or trousers?',
        summary: 'In general: wash as little as possible and as...',
        image: 'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.full',
        brandId: '122278',
    },
    {
        id: 69155,
        url: 'https://inspector.brakeable.com/ufixit/69155',
        title: 'What’s the best way to wash and dry my Vaude softshell jacket?',
        summary: 'In general, waterproof, water repellent and...',
        image: 'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.full',
        brandId: '122278',
    },
    {
        id: 69157,
        url: 'https://www.ifixit.com/Guide/How+do+I+wash+and+dry+my+Vaude+down+sleeping+bag-/69157',
        title: 'How do I wash and dry my Vaude down sleeping bag?',
        summary: 'As a rule of thumb, you should wash your...',
        image: 'https://guide-images.cdn.ifixit.com/igi/qYkLfUqRuQ6srFhu.full',
        brandId: '122278',
    },
    {
        id: 69208,
        url: 'https://www.ifixit.com/Guide/How+do+I+sew+a+button+onto+my+Vaude+shirt-/69208',
        title: 'How do I sew a button onto my Vaude shirt?',
        summary: 'Buttons can occasionally come off due to...',
        image: 'https://guide-images.cdn.ifixit.com/igi/cKDLpcLLvBqaXrIu.full',
        brandId: '122278',
    },
    {
        id: 71477,
        url: 'https://inspector.brakeable.com/ufixit/71477',
        title: 'How do I replace a sternum strap with a hydration tube holder on my backpack?',
        summary: 'If the sternum strap comes off of its rail or...',
        image: 'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.full',
        brandId: '122278',
    },
    {
        id: 74498,
        url: 'https://www.ifixit.com/Guide/How+do+I+waterproof+my+tent-/74498',
        title: 'How do I waterproof my tent?',
        summary: 'Weather conditions like sunlight and strong...',
        image: 'https://guide-images.cdn.ifixit.com/igi/sWaVfUhBQ1uQPNvq.full',
        brandId: '122278',
    },
    {
        id: 74517,
        url: 'https://inspector.brakeable.com/ufixit/74517',
        title: 'How do I attach a new snap?',
        summary: 'During prolonged or heavy use, a snap can come...',
        image: 'https://guide-images.cdn.ifixit.com/igi/rTyQjDhPMJQmaMRE.full',
        brandId: '122278',
    },
    {
        id: 74524,
        url: 'https://www.ifixit.com/Guide/How+do+I+replace+a+brittle+or+broken+shock+cord-/74524',
        title: 'How do I replace a brittle or broken shock cord?',
        summary: 'Shock cords hold the segments of a tent pole...',
        image: 'https://guide-images.cdn.ifixit.com/igi/p1Bk3iaKf4JCpBGW.full',
        brandId: '122278',
    },
    {
        id: 74532,
        url: 'https://inspector.brakeable.com/ufixit/74532',
        title: 'How do I patch a garment?',
        summary: 'Elbows and knees are classic areas of wear on...',
        image: 'https://guide-images.cdn.ifixit.com/igi/nshdoEAeS5MjKUHh.full',
        brandId: '122278',
    },
    {
        id: 74786,
        url: 'https://www.ifixit.com/Guide/How+do+I+stuff+a+hole+on+my+Vaude+knit+sweater-/74786',
        title: 'How do I stuff a hole on my Vaude knit sweater?',
        summary: 'A hole in a knit garment can easily be closed...',
        image: 'https://guide-images.cdn.ifixit.com/igi/VUcNXXVZHwEWUBNs.full',
        brandId: '122278',
    },
]
