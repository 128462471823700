const de = {
    common: {
        next: 'Weiter',
        back: 'Zurück',
        submit: 'Absenden',
        go: 'Los',
        or: 'Oder',
        logout: 'Abmelden',
        loading: 'Laden...',
        error: 'Etwas ist schiefgelaufen',
        copied: 'Kopiert!',
        language: 'Sprache',
    },
    admin: {
        newCompanyRepresentative: {
            title: 'Neue*r Kund*in einladen',
            companyNameLabel: 'Firmenname',
            companyRepresentativeNameLabel: 'Mitarbeiter*in Name',
            companyRepresentativeEmailLabel: 'Mitarbeiter*in E-Mail',
            buttonLabel: 'Einladen',
        },
        unverifiedUsers: {
            title: 'Unverifizierte Benutzer*innen',
            table: {
                company: 'Firma',
                representative: 'Repräsentant',
                email: 'E-Mail',
                link: 'Einladungslink',
            },
        },
        companies: {
            title: 'Firmen',
            table: {
                id: 'Id',
                company: 'Firma',
                representative: 'Repräsentant',
                representativeEmail: 'E-Mail',
                apps: 'Apps',
                productCount: 'Anzahl Produkte',
            },
        },
    },
    company: {
        onboarding: {
            form: {
                welcome: 'Willkommen!',
                lead: 'Wir sind froh, euch an Bord zu haben.',
                subLead: 'Bitte aktiviere deinen Account:',
                email: {
                    label: 'E-Mail',
                    placeholder: 'E-Mail',
                    helperText:
                        'Bitte verwende die E-Mail, auf die du diesen Einladungslink erhalten hast.',
                },
                password: {
                    label: 'Passwort',
                    placeholder: 'Setze ein neues, sicheres Passwort',
                },
                requirements: {
                    title: 'Passwortanforderungen:',
                    length: 'Mindestens 8 Zeichen',
                    casing: 'Klein- und Grossbuchstaben',
                    number: 'Mindestens eine Zahl',
                    special: 'Mindestens ein Sonderzeichen',
                },
                action: 'Account erstellen',
            },
            introduction: {
                title: 'Willkommen auf der Inspector Platform. Ihre Reise zu einer verbesserten Produktqualität und bereicherten Nutzererlebnissen beginnt hier. Wir freuen uns, Sie an Bord zu haben, und freuen uns darauf, Ihren Erfolg zu unterstützen.',
                action: 'Los geht’s',
            },
        },
        common: {
            filterbar: {
                search: {
                    label: 'Suche',
                    placeholder:
                        "Suche nach E-Mail, Beschreibung oder Datum (Bsp. '11. November' oder 'Januar')",
                },
                unseen: {
                    showOnlyUnseen: 'Zeige nur neue Inspections',
                    showAll: 'Zeige alle Inspections',
                },
                share: 'Teilen',
            },
        },
        inspector: {
            home: {
                products: {
                    title: 'Produkte',
                    action: 'Neues Produkt anlegen',
                    productList: {
                        copyInspectorLink: {
                            copyDesktop: 'Inspector-Link kopieren',
                            copyMobile: 'Inspector-Link',
                            copied: 'Inspector-Link kopiert',
                        },
                        deleteProduct: {
                            headline: 'Bist du sicher?',
                            confirmText:
                                'Willst du das Produkt <strong>"{{productName}}"</strong> wirklich <strong>unwiderruflich</strong> löschen? Alle Daten werden gelöscht und können nicht wiederhergestellt werden.',
                            cancel: 'Abbrechen',
                            delete: 'Löschen',
                        },
                    },
                    newProduct: {
                        title: 'Neues Produkt anlegen',
                        form: {
                            name: {
                                label: 'Produktname',
                                required: 'Produktname ist erforderlich',
                            },
                            cancelButton: {
                                label: 'Abbrechen',
                            },
                            saveButton: {
                                label: 'Produkt anlegen',
                                saving: 'Speichern...',
                                validation: {
                                    nameRequired: 'Produktname ist erforderlich',
                                    imageRequired: 'Produktbild ist erforderlich',
                                },
                            },
                            photoStep: {
                                title: 'Produktbild hochladen',
                                subtitle:
                                    'Nimm ein Foto mit deiner Kamera auf oder lade ein Foto aus deiner Galerie hoch.',
                                takePhotoButton: 'Foto aufnehmen',
                                uploadPhotoButton: 'Foto hochladen',
                            },
                            infoStep: {
                                title: 'Produktinformationen',
                                subtitle: 'Gib deinem Produkt einen Namen.',
                                label: 'Produktname',
                                placeholder: 'Produktname',
                            },
                            summaryStep: {
                                title: 'Zusammenfassung',
                                subtitle:
                                    'Bitte überprüfe die Angaben und bestätige die Erstellung des Produktes.',
                                loading:
                                    'Wir optimieren gerade dein Produktbild, warte bitte einen Moment...',
                                unhappy: 'Nicht zufrieden?',
                                useOriginalBtn: 'Originalbild stattdessen verwenden',
                                useOptimizedBtn: 'Optimiertes Bild verwenden',
                                nextBtn: 'Anlegen',
                            },
                        },
                    },
                },
                share: {
                    exportProductList: 'Produktliste exportieren',
                    printProductCards: 'Produktkarten drucken',
                    orderCompanyCard: 'Firmenkarte bestellen',
                    copyCompanyLink: 'Firmenübersichtslink kopieren',
                    copyCompanySupportLink: 'Firmen Reparaturlink kopieren',
                    companyLinkCopied: 'Link kopiert',
                },
                onboarding: {
                    title: 'Produkte',
                    action: 'Produkt anlegen',
                },
            },
            productDashboard: {
                backToOverview: 'Zurück zur Übersicht',
                keyFacts: {
                    pageViews: 'Seitenaufrufe',
                    problems: 'Probleme',
                    happySubmissionFeedback: 'Keine Anmerkung',
                    conversion: 'Conversion',
                },
                noDescription: 'Keine Beschreibung vorhanden',
                aiFilter: 'AI Filter',
                originFilter: 'Herkunft',
                detail: {
                    defectDescription: 'Defektbeschreibung',
                    contextDescription: 'Produktnutzung',
                    contact: 'Kontakt E-Mail für Rückfragen:',
                },
                noSubmissions: {
                    title: 'Noch keine Inspections',
                    subTitle:
                        'Sobald deine Nutzer:innen eine Inspection durchgeführt haben, werden sie hier angezeigt.',
                    copyInspectionLink: 'Inspector-Link kopieren',
                    copied: 'Link kopiert',
                },
            },
        },
        report: {
            title: 'Inspections',
        },
        userManagement: {
            users: {
                title: 'Users',
                action: 'Neuer User anlegen',
                userList: {
                    subtitle: 'Produkte: {{ productCount }} | Inspections: {{ inspectionCount }}',
                },
            },
        },
        support: {
            supportRequests: {
                title: 'Supportanfragen',
                kanban: {
                    open: 'Offen',
                    inProgress: 'In Bearbeitung',
                    done: 'Erledigt',
                },
                noSupportRequestsFallback: 'Du hast noch keine Supportanfragen. Gut gemacht!',
            },
        },
        companySettings: {
            account: {
                title: 'Account',
                newEmployeeForm: {
                    name: 'Name',
                    email: 'E-Mail',
                    button: 'Einladen',
                },
                logoForm: {
                    title: 'Firmenlogo hochladen',
                    helperText: 'Idealerweise ein Logo ohne Abstand zum Rand',
                    loading: 'Datei wird hochgeladen...',
                    success: 'Logo erfolgreich hochgeladen',
                    failed: 'Irgendetwas ist schiefgelaufen',
                },
            },
            settings: {
                title: 'Einstellungen',
                basicSettings: {
                    photoOrigin: {
                        title: 'Fotoherkunft',
                        lead: 'Sollen die User Fotos nur mit der Kamera aufnehmen oder auch aus der Galerie hochladen können?',
                        cameraOnly: 'Nur Kamera möglich',
                        cameraAndGallery: 'Kamera und Galerie möglich',
                    },
                    emailMandatory: {
                        title: 'E-Mail als Pflichfeld',
                        lead: 'Sollen die User ihre E-Mail-Adresse angeben müssen oder soll dies freiwillig sein?',
                        mandatory: 'E-Mail ist ein Pflichtfeld',
                        optional: 'E-Mail ist freiwillig',
                    },
                    emailNotifications: {
                        title: 'E-Mail Benachrichtigung',
                        lead: 'Willst du eine E-Mail erhalten, wenn eine neue Inspection abgegeben wurde?',
                        enabled: 'E-Mail Benarichtigungen eingeschalten',
                        disabled: 'E-Mail Benarichtigungen ausgeschalten',
                    },
                },
                customerSettingsOnlyWarning:
                    'Diese Einstellungen können nur in der Customer Version der Inspector App verwendet werden.',
                customerSettings: {
                    postInspectionRedirect: {
                        title: 'Weiterleitung nach Inspection',
                        lead: 'Soll der User nach einer Inspection auf eine spezifische Seite weitergeleitet werden?',
                        label: 'Weiterleitung nach Inspection',
                        restart: 'Startbildschirm',
                        companyPage: 'Unternehmensseite',
                        companyPageValidation: {
                            required: 'Unternehmensseite muss angegeben werden.',
                            invalid: 'Bitte eine gültige Unternehmensseite angeben.',
                        },
                    },
                    allowHappySubmissions: {
                        title: '"Keine Anmerkung" erlauben',
                        lead: 'Sollen die User die Möglichkeit haben, eine Inspection ohne Anmerkung zu speichern?',
                        enabled: '"Keine Anmerkung" möglich',
                        disabled: '"Keine Anmerkung" nicht möglich',
                    },
                    allowRemindMeLater: {
                        title: 'Später erinnern" erlauben',
                        lead: 'Sollen die User die Möglichkeit haben, sich per E-Mail an ihre Inspection erinnern zu lassen?',
                        enabled: '"Später erinnern" möglich',
                        disabled: '"Später erinnern" nicht möglich',
                    },
                    allowContactRequests: {
                        title: 'Kontaktanfragen erlauben',
                        lead: 'Sollen die User die Möglichkeit haben, Kontaktanfragen zu beantragen, damit du mit ihnen in Kontakt treten kannst?',
                        enabled: 'Kontaktanfragen möglich',
                        disabled: 'Kontaktanfragen nicht möglich',
                    },
                    showDashboardAnalytics: {
                        title: 'Analysedaten anzeigen',
                        lead: 'Willst du im Dashboard Analysedaten sehen?',
                        enabled: 'Analysedaten anzeigen',
                        disabled: 'Analysedaten nicht anzeigen',
                    },
                },
                save: 'Speichern',
                saving: 'Speichern...',
                success: 'Einstellungen gespeichert',
                error: 'Beim Speichern der Einstellungen ist ein Fehler aufgetreten',
            },
        },
    },
    publicCompany: {
        profile: {
            title: 'Product Inspection',
            lead: 'Danke, dass du uns Hilfst, unsere Produkte langlebiger zu machen. Wähle unten das zu inspizierende Produkt aus:',
            help: 'Hilfe',
        },
        introCarousel: {
            title: 'Intro zu Inspector',
            s1: {
                title: 'Produkt auswählen',
                lead: 'Wähle das Produkt aus, für das du eine Inspection durchführen möchtest.',
            },
            s2: {
                title: 'Bereich markieren',
                lead: 'Markiere den Bereich des Defektes oder des Verbesserungsvorschlags.',
            },
            s3: {
                title: 'Foto aufnehmen',
                lead: 'Nimm ein Foto von der Problemstelle auf',
            },
            s4: {
                title: 'Problem beschreiben',
                lead: 'Beschreibe den Defekt oder Verbesserungsvorschlag.',
            },
            s5: {
                title: 'Geschichte erzählen',
                lead: 'Erzähle uns die Geschichte deines Produktes und des Problems.',
            },
        },
        support: {
            title: 'Reparaturanfrage',
            lead: 'Wenn du ein Problem mit deinem Produkt hast, starte eine Reparaturanfrage, damit wir gemeinsam eine Lösung finden können.',
        },
    },
    inspector: {
        desktop: {
            title: 'Bitte öffne die INSPECTION auf deinem Handy',
            description: 'Scanne hierzu den QR-Code mit deinem Handy',
        },
        mobile: {
            navigation: {
                from: 'von',
                submit: 'Absenden',
            },
            intro: {
                title: 'Produkt Inspection',
                description:
                    'Wenn du einen Verbesserungsvorschlag oder Defekt an deinem Produkt hast, mach eine  Inspection.',
                action: 'Inspection starten',
                nothingToReport: {
                    action: 'Keine Anmerkungen',
                },
            },
            pinpoint: {
                title: 'Problemstelle markieren',
                description:
                    'Klicke auf die entsprechende Stelle im Bild, um einen <strong>Verbesserungsvorschlag</strong> oder <strong>Defekt</strong> zu markieren',
            },
            photo: {
                title: 'Problem Aufnehmen',
                description: 'Mach ein <strong>Foto</strong> von der eben markierten Stelle.',
                preview: 'Vorschau',
                action: 'Foto aufnehmen',
                reAction: 'Neues Foto aufnehmen',
                itemNotAvailable: 'Produkt gerade nicht zur Hand',
                cancelled: {
                    description:
                        'Du hast die Aufnahme abgebrochen. Wenn es daran lag, dass du Schwarz gesehen hast, liegt das möglicherweise an den Berechtigungen. Unter folgendem Link kannst du sehen, wie du diese anpassen kannst.',
                    showMe: 'Zeig mir wie',
                    dismiss: 'Schliessen',
                },
                remindMeLater: {
                    title: 'Inspection später durchführen',
                    lead: 'Du hast das Produkt gerade nicht zur Hand? Kein Problem! Wir erinnern dich später per E-Mail daran, eine Inspection durchzuführen.',
                    emailLabel: 'E-Mail',
                    datetimeLabel: 'Erinnerungszeitpunkt',
                    outro: 'Deine E-Mail wird ausschliesslich für diesen Zweck verwendet.',
                    submit: 'Erinnere mich später',
                    cancel: 'Abbrechen',
                },
            },
            description: {
                title: 'Problem beschreiben',
                description:
                    'Beschreibe deinen <strong>Defekt</strong> oder <strong>Verbesserungsvorschlag</strong>.',
                label: 'Beschreibung',
                placeholder: 'Problembeschrieb',
            },
            contextDescription: {
                title: 'Mehr Informationen',
                description:
                    'Beschreibe die <strong>Geschichte</strong> deines <strong>Produktes</strong> und <strong>Problems</strong>.',
                label: 'Beschreibung',
                placeholder: 'Produkt-/Problemgeschichte',
            },
            contact: {
                title: 'Kontakt angeben',
                description:
                    'Bitte hinterlasse uns deine E-Mail, damit wir dich für <strong>Rückfragen zu deiner Inspection</strong> kontaktieren können.',
                label: 'E-Mail',
                placeholder: 'E-Mail',
                requestContact: 'Bitte kontaktiert mich mit einer Lösung für mein Problem',
                photoUploadLabel: 'Foto-Upload',
            },
            outro: {
                title: 'Dankeschön!',
            },
        },
    },
    support: {
        desktop: {
            title: 'Bitte öffne die Reparaturanfrage auf deinem Handy',
            description: 'Scanne hierzu den QR-Code mit deinem Handy',
        },
        mobile: {
            navigation: {
                from: 'von',
                submit: 'Absenden',
            },
            intro: {
                title: 'Reparaturanfrage',
                description:
                    'Wenn du ein Problem mit deinem Produkt hast, starte eine Reparaturanfrage.',
                action: 'Anfrage starten',
                nothingToReport: {
                    action: 'Keine Anmerkungen',
                },
            },
            pinpoint: {
                title: 'Problemstelle markieren',
                description:
                    'Klicke auf die entsprechende Stelle im Bild, um einen <strong>Defekt</strong> zu markieren',
            },
            photo: {
                title: 'Problem Aufnehmen',
                description: 'Mach ein <strong>Foto</strong> von der eben markierten Stelle.',
                preview: 'Vorschau',
                action: 'Foto aufnehmen',
                reAction: 'Neues Foto aufnehmen',
                itemNotAvailable: 'Produkt gerade nicht zur Hand',
                cancelled: {
                    description:
                        'Du hast die Aufnahme abgebrochen. Wenn es daran lag, dass du Schwarz gesehen hast, liegt das möglicherweise an den Berechtigungen. Unter folgendem Link kannst du sehen, wie du diese anpassen kannst.',
                    showMe: 'Zeig mir wie',
                    dismiss: 'Schliessen',
                },
                remindMeLater: {
                    title: 'Inspection später durchführen',
                    lead: 'Du hast das Produkt gerade nicht zur Hand? Kein Problem! Wir erinnern dich später per E-Mail daran, eine Inspection durchzuführen.',
                    emailLabel: 'E-Mail',
                    datetimeLabel: 'Erinnerungszeitpunkt',
                    outro: 'Deine E-Mail wird ausschliesslich für diesen Zweck verwendet.',
                    submit: 'Erinnere mich später',
                    cancel: 'Abbrechen',
                },
            },
            description: {
                title: 'Problem beschreiben',
                description: 'Beschreibe den <strong>Defekt</strong> deines Produkts.',
                label: 'Beschreibung',
                placeholder: 'Problembeschrieb',
            },
            orderReference: {
                title: 'Auftragsreferenz eingeben',
                description:
                    'Gib deine <strong>Bestellnummer</strong> (online) oder deine <strong>Auftragsnummer</strong> (im Laden) ein.',
                label: 'Auftragsreferenz',
                placeholder: 'Bestellnummer / Auftragsnummer',
            },
            contact: {
                title: 'Kontakt angeben',
                description:
                    'Bitte hinterlasse uns deine E-Mail, damit wir dich für <strong>Rückfragen zu deiner Anfrage</strong> kontaktieren können.',
                hint: 'Verwende wenn möglich die E-Mail Adresse, die du beim Kauf angebeben hast.',
                label: 'E-Mail',
                placeholder: 'E-Mail',
                requestContact: 'Bitte kontaktiert mich mit einer Lösung für mein Problem',
                photoUploadLabel: 'Foto-Upload',
            },
            outro: {
                title: 'Dankeschön!',
            },
        },
    },

    poc: {
        support: {
            linktree: {
                title: 'Wie können wir Ihnen heute helfen?',
                subtitle: 'Wähle unten eine Option aus',
                repair: 'Reparaturanfrage',
                repairSub: 'Eine Reparaturanfrage für ein Produktproblem stellen',
                warranty: 'Garantiefall',
                warrantySub: 'Ein Problem mit Ihrem Produkt unter Garantie melden',
                feedback: 'Produkt-Feedback',
                feedbackSub: 'Vorschläge oder Kritik zu unseren Produkten mitteilen',
                diy: 'Reparaturanleitung finden',
                diySub: 'Defekte an Ihrem Produkt mit unseren DIY-Anleitungen selber lösen',
            },
            repairIntroStep: {
                title: 'Reparaturanfrage',
                description:
                    'Haben Sie einen Defekt an Ihrem Produkt? Stellen Sie hier eine Reparaturanfrage.',
                action: 'Reparaturanfrage starten',
                backAction: 'Zurück zum Start',
                multipleCapturingInfo:
                    'Um mehrere Reparaturen anzufordern, können Sie zusätzliche Anfragen am Ende anhängen.',
            },
            feedbackIntroStep: {
                title: 'Produktfeedback',
                description:
                    'Haben Sie einen Verbesserungsvorschlag oder eine Kritik an Ihrem Produkt haben, können Sie uns dies hier mitteilen.',
                action: 'Produktfeedback abgeben',
                backAction: 'Zurück zum Start',
                multipleCapturingInfo:
                    'Um Mehrere Feedbacks abzugeben, können Sie am Ende weitere Feedbacks anhängen.',
            },
            warrantyIntroStep: {
                title: 'Garantiefall melden',
                description:
                    'Haben Sie ein Problem mit Ihrem Produkt? Melden Sie hier einen Garantiefall.',
                action: 'Garantiefall melden',
                backAction: 'Zurück zum Start',
            },
            diyIntroStep: {
                title: 'Selber reparieren',
                description:
                    'Haben Sie ein Problem mit Ihrem Produkt? Finden Sie die passende Reparaturanleitung.',
                action: 'Reparaturanleitung finden',
                backAction: 'Zurück zum Start',
            },
            emailStep: {
                title: 'E-Mail',
                subtitle:
                    'Bitte geben Sie Ihre E-Mail-Adresse an, damit wir Ihre Anfrage identifizieren und Ihnen antworten können.',
                repairSubtitle:
                    'Bitte geben Sie Ihre E-Mail-Adresse an, damit wir die Bestellbestätigung und das Versandetikett senden können.',
                feedbackSubtitle:
                    'Bitte hinterlassen Sie uns Ihre E-Mail, damit wir Sie für mögliche Rückfragen kontaktieren können.',
                label: 'E-Mail-Adresse',
                placeholder: '',
            },
            productStep: {
                title: 'Für welches Produkt benötigen Sie Hilfe?*',
                subtitle: '*Falls du mehrere Probleme hast, können Sie diese am Ende hinzufügen',
                form: {
                    productReference: 'Produktreferenz eingeben',
                },
                categories: {
                    all: 'Alle',
                    apparel: 'Bekleidung',
                    upperwear: 'Oberteile',
                    tshirt: 'T-Shirts',
                    pullover: 'Pullover',
                    jacket: 'Jacken',
                    bikini: 'Bikini',
                    pants: 'Hosen',
                    skiingPants: 'Skihosen',
                    accessories: 'Accessoire',
                    beanie: 'Mützen',
                    gloves: 'Handschuhe',
                    shoes: 'Schuhe',
                    hikingShoes: 'Wanderschuhe',
                    snowBoots: 'Schneestiefel',
                    sportShoes: 'Sportschuhe',
                    sneakers: 'Sneakers',
                    flipflop: 'Flip Flop',
                    hardware: 'Hardware',
                    camping: 'Camping',
                    tents: 'Zelte',
                    campingChairs: 'Campingstühle',
                    mats: 'Matten und Matratzen',
                    sleepingBags: 'Schlafsäcke',
                    hiking: 'Wandern',
                    backpack: 'Rucksäcke',
                    hikingPole: 'Wanderstöcke',
                    watersport: 'Wassersport',
                    sup: 'SUP',
                },
                views: {
                    front: 'Vorderseite',
                    back: 'Rückseite',
                    inside: 'Innen',
                },
            },
            descriptionStep: {
                title: 'Problem beschreiben',
                subtitle:
                    'Bitte beschreiben Sie das Problem mit Ihrem Produkt, damit wir Ihnen effektiv helfen können.',
                label: 'Problembeschreibung',
                placeholder: '',
            },
            PhotoStep: {
                title: 'Foto des Problems hochladen',
                QrCodeAlternativeTitle: 'QR Code Scannen',
                QrCodeAlternative:
                    'Alternativ kannst du auch den QR-Code scannen um mit deinem Handy ein Foto aufzunehmen.',
                dropzone: {
                    label: 'Ziehe ein Foto hier hin zum Hochladen',
                    description: 'Wähle vorzugsweise hochauflösende, klare Fotos des Problems',
                    dropLabel: 'Platziere das Foto hier',
                    button: 'Foto auswählen',
                },
                mobile: {
                    label: 'Foto aufnehmen',
                    description: 'Wähle vorzugsweise hochauflösende, klare Fotos des Problems',
                    action: 'Foto hochladen',
                    retakeAction: 'Foto erneut aufnehmen',
                },
            },
            diyStep: {
                title: 'Reparaturanleitungen',
                subtitle:
                    'Basierend auf Ihrer Beschreibung könnten Sie das Problem mit den folgenden Anleitungen selbst lösen:',
                guides: {
                    howToWash: {
                        title: 'Wie wasche und trockne ich meine Membran-Jacke/-Hose?',
                    },
                    howToRepairHole: {
                        title: 'Wie repariert man ein Loch in einem gewebtem Textil?',
                    },
                    howToRepairQuickLock: {
                        title: 'Wie man das Quick-Lock-System an seiner Fahrradtasche selbst repariert',
                    },
                    clickHere: 'Klicke hier für einen Step-by-Step Guide',
                },
                skip: 'Überspringen',
                guideBy_one: 'Anleitung von',
                guideBy_other: 'Anleitungen von',
                checkoutGuideAction: 'Anleitung ansehen',
                notMatching: 'Keine passende Anleitung dabei?',
                nothingFoundSubtitle:
                    'Leider konnten wir keine passende Reparaturanleitung finden.',
                requestNewGuideAction: 'Eine neue Anleitung anfragen',
                newGuideActionRequested: 'Anfrage gesendet',
                findeRepairCenterAction: 'Reparaturcenter in deiner Nähe finden',
                goBackAction: 'Problembeschreibung anpassen',
                checkoutRepairOfferAction: 'Reparaturangebot ansehen',
            },
            solutionStep: {
                linktree: {
                    title: 'Reparaturoptionen',
                    diyTitle: 'Entdecken Sie unsere DIY-Lösungen',
                    diySubtitle:
                        'Versuchen Sie, das Problem selbst mit unseren Anleitungen zu beheben.',
                    servicesTitle: 'Überprüfen Sie unsere Kostenschätzung',
                    servicesSubtitle:
                        'Überprüfen Sie die geschätzten Reparaturkosten basierend auf Ihrer Fehlerbeschreibung.',
                },
            },
            costEstimationStep: {
                title: 'Kostenschätzung',
                costRange: {
                    title: 'Reparaturkostenbereich',
                    subtitle:
                        'Diese geschätzten Reparaturkosten basieren auf Ihren Fotos und Beschreibungen.',
                },
                assignedServices: {
                    title: 'Zugewiesene Reparaturdienste',
                    subtitle:
                        'Hier sind die potenziellen Reparaturdienste, die wir für Sie identifiziert haben.',
                },
                diyAction: 'Ich bevorzuge eine DIY-Lösung.',
                repairAction: 'Ich möchte meinen Artikel reparieren lassen',
            },
            contactStep: {
                title: 'Kontaktangaben',
                form: {
                    title: 'Persönliche Angaben',
                    emailLabel: 'E-Mail',
                    maxRepairCostLabel: 'Maximale Reparaturkosten',
                    acceptTermsLabel:
                        'Ich bin mit einer Reparatur bis zur angegebenen Preisobergrenze einverstanden',
                    addMoreDefects: 'Weitere Reparatur hinzufügen',
                    submit: 'Kostenpflichtig bestellen',
                },
            },
            servicesStep: {
                title: 'Kostenschätzung',
                subtitle:
                    'Basierend auf Ihrer Problembeschreibung kostet die Reparatur voraussichtlich:',
                price: 'Preis: {{ price }}',
                aiSuggestionLead: 'Zugewiesene Dienste anzeigen',
                remainingLead: 'Dienste manuell auswählen',
                costConfirmationPre:
                    'Ich bin mit einer Reparatur bis zu folgendem Maximalbetrag einverstanden',
                costConfirmationLabel: 'Kostendach',
            },
            pinpointStep: {
                next: 'Absenden',
            },
            claimProbabilityStep: {
                title: 'Garantieabdeckung',
                subtitle:
                    'Basierend auf Ihrer Beschreibung ist die Wahrscheinlichkeit, dass dieses Problem von unserer Garantie abgedeckt wird:',
                knowWhy: 'Erfahre wieso',
                whyNot: 'Geringe Wahrscheinlichkeit: Dieses Problem wird wahrscheinlich nicht von der Garantie abgedeckt. Wir empfehlen, die folgenden Selbsthilfe-Lösungen auszuprobieren oder die Reparaturkosten zu überprüfen.',
                why: 'Hohe Wahrscheinlichkeit: Wir bieten eine lebenslange Garantie auf Wasserdichtigkeit und übernehmen die Reparaturkosten, wenn Ihr Produkt nicht mehr wasserdicht ist.',
                alternativeSteps:
                    'Aufgrund der geringen Erfolgsaussichten zeigen wir dir mögliche Lösungen, die du selbst ausprobieren kannst, oder nennen dir potenzielle Reparaturkosten.',
            },
            warrantyCostEstimationStep: {
                title: 'Kostenschätzung',
                subtitle:
                    'Wenn das Problem nicht von der Garantie abgedeckt ist, würde die Reparatur voraussichtlich kosten:',
            },
            repairOutroStep: {
                title: 'Dankeschön',
                subtitle:
                    'Den Versandschein zusammen mit der Auftragsbestätigung erhälst du per E-Mail.',
                restartAction: 'Einen weiteren Defekt erfassen',
                action: 'Zurück zur Übersicht',
            },
            feedbackOutroStep: {
                title: 'Dankeschön',
                subtitle:
                    'Vielen Dank für das wertvolle Feedback, wir schätzen das und versuchen, uns zu verbessern.',
                restartAction: 'Ein weiteres Feedback hinterlassen',
                action: 'Zurück zur Übersicht',
            },
            warrantyOutroStep: {
                title: 'Dankeschön',
                subtitle: 'Wir melden uns bald bei dir.',
                action: 'Zurück zum Start',
            },
        },
    },
}

export type TranslationsT = typeof de

export default de
