import React from 'react'
import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import DateTimePicker from '../../../../../components/date-time-picker'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import T from '../../../../../components/typography/t'

export type RemindMeFormData = {
    email: string
    datetime: dayjs.Dayjs
}

const RemindMe = ({
    close,
    isOpen,
    handleSubmit,
}: {
    close: () => void
    isOpen: boolean
    handleSubmit: (data: RemindMeFormData) => void
}) => {
    const {
        register,
        handleSubmit: handleFormSubmit,
        formState: { errors },
        getValues,
        setValue,
    } = useForm<RemindMeFormData>({ defaultValues: { datetime: dayjs().add(1, 'hour') } })
    const [t] = useTranslation()

    const onDateTimeChange = (datetime: dayjs.Dayjs) => {
        setValue('datetime', datetime)
    }

    const onSubmit = (data: RemindMeFormData) => {
        handleSubmit(data)
        close()
    }

    return (
        <div>
            <Dialog open={isOpen} onClose={close}>
                <DialogTitle>
                    <T i18nKey="inspector.mobile.photo.remindMeLater.title" />
                </DialogTitle>
                <DialogContent>
                    <T i18nKey="inspector.mobile.photo.remindMeLater.lead" />
                    <TextField
                        data-testid="remind-me-later-email"
                        label={t('inspector.mobile.photo.remindMeLater.emailLabel' as any)}
                        type="email"
                        {...register('email', {
                            required: 'Email is required',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email address',
                            },
                        })}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        fullWidth
                        margin="normal"
                    />
                    <DateTimePicker
                        label={t('inspector.mobile.photo.remindMeLater.datetimeLabel' as any)}
                        onChange={onDateTimeChange}
                        value={getValues('datetime')}
                        disablePast
                        error={!!errors.datetime}
                        helperText={errors.datetime?.message}
                        fullWidth
                        margin="normal"
                    />
                    <T
                        i18nKey="inspector.mobile.photo.remindMeLater.outro"
                        sx={{ mt: 2, color: 'grey.400', fontSize: '0.8rem' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={close}>
                        <T i18nKey="inspector.mobile.photo.remindMeLater.cancel" />
                    </Button>
                    <Button
                        onClick={handleFormSubmit(onSubmit)}
                        variant="contained"
                        color="primary"
                        data-testid="remind-me-later-submit-button"
                    >
                        <T i18nKey="inspector.mobile.photo.remindMeLater.submit" />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default RemindMe
