import React from 'react'
import Intro from './steps/intro'
import PinpointStep from './steps/pinpoint-step'
import PhotoStep from './steps/photo-step'
import DescriptionStep from './steps/description-step'
import ContextDescriptionStep from './steps/context-description-step'
import ContactStep from './steps/contact-step'
import { UploadClient } from '@uploadcare/upload-client'
import {
    ErrorCtx,
    Form,
    FormValues,
    InspectorMultiStepFormContextType,
    Meta,
    MultiStepFormError,
    StepCtx,
    StepProps,
} from './types'
import Outro from './steps/outro'
import { useAnalytics } from 'use-analytics'
import { InspectorPageViewMobileEvent } from '../../../../shared/analytics/index'
import useInspectorEmailcookie from '../email-cookie'
import { useCopilotAction } from '@copilotkit/react-core'

let slides: Array<() => JSX.Element> = [
    () => <Intro />,
    () => <PinpointStep />,
    () => <PhotoStep />,
    () => <DescriptionStep />,
    () => <ContactStep />,
    () => <Outro />,
]

const defaultContextValues: InspectorMultiStepFormContextType = {
    ctx: {} as StepCtx,
    props: {} as StepProps,
    form: {} as Form,
    meta: {} as Meta,
    error: {} as ErrorCtx,
    uploadCareClient: {} as UploadClient,
}

export const InspectorMultiStepFormContext =
    React.createContext<InspectorMultiStepFormContextType>(defaultContextValues)

const InspectorMultiStepForm = ({
    onSubmit,
    source,
    uploadCareClient,
    ...ctx
}: StepCtx & {
    onSubmit: (formValues: FormValues, anonymousUserId: string) => Promise<void>
    source: string
    uploadCareClient: UploadClient
}) => {
    const analytics = useAnalytics()
    const [getEmailAddressFromCookies] = useInspectorEmailcookie()
    const [meta, setMeta] = React.useState<any>({})
    const [slideIndex, setSlideIndex] = React.useState(0)
    const [errors, setErrors] = React.useState<MultiStepFormError[]>([])
    const [formValues, setFormValues] = React.useState<FormValues>({
        inspectionImageUrl: undefined,
        inspectionImagePinpoint: undefined,
        inspectionDescription: undefined,
        inspectionContextDescription: undefined,
        inspectionEmail: getEmailAddressFromCookies() ?? '',
        followUpQuestion: undefined,
    })
    const nextSlide = () => setSlideIndex(Math.min(slideIndex + 1, maxSteps - 1))
    const prevSlide = () => setSlideIndex(Math.max(slideIndex - 1, 0))
    const setSlide = (index: number) => setSlideIndex(index)
    const maxSteps = slides.length
    const gotoEnd = () => setSlideIndex(maxSteps - 1)
    const currentStep = slideIndex

    const setInspectionImageUrl = (url?: string) =>
        setFormValues((v) => ({ ...v, inspectionImageUrl: url }))
    const setInspectionImagePinpoint = (pinpoint: { x: number; y: number }) => {
        setFormValues((v) => ({ ...v, inspectionImagePinpoint: pinpoint }))
    }
    const setInspectionDescription = (description?: string) => {
        setFormValues((v) => ({ ...v, inspectionDescription: description }))
    }
    const setInspectionContextDescription = (contextDescription?: string) => {
        setFormValues((v) => ({ ...v, inspectionContextDescription: contextDescription }))
    }
    const setInspectionEmail = (email: string) => {
        setFormValues((v) => ({ ...v, inspectionEmail: email }))
    }
    const setAIFollowUpQuestion = (question: string) => {
        setFormValues((v) => ({ ...v, followUpQuestion: question }))
    }
    const setContactRequested = (flag: boolean) => {
        setFormValues((v) => ({ ...v, contactRequested: flag }))
    }
    const submitForm = async () => {
        const user = analytics.user()
        await onSubmit(formValues, user.anonymousId)
    }
    const pushError = (error: MultiStepFormError) => {
        setErrors((errors) => [...errors, error])
    }
    const popError = () => {
        setErrors((errors) => errors.slice(0, errors.length - 1))
    }

    React.useEffect(() => {
        analytics.track(InspectorPageViewMobileEvent, {
            questionaireUuid: ctx.questionaireUuid,
            source,
        })
    }, [])

    const addFollowUpStep = () => {
        if (slides.length > 6) {
            return
        }
        slides = [...slides.slice(0, 4), () => <ContextDescriptionStep />, ...slides.slice(4)]
    }
    const removeFollowUpStep = () => {
        if (slides.length < 7) {
            return
        }
        slides = [...slides.slice(0, 4), ...slides.slice(5)]
    }

    useCopilotAction({
        name: 'formulateFollowUpQuestion',
        description: 'formulate a follow up question to ask the user about the missing information',
        parameters: [
            {
                name: 'followUpQuestion',
                type: 'string',
                required: false,
                description: 'The follow up question to ask the user',
            },
        ],
        handler: async ({ followUpQuestion }) => {
            console.log(followUpQuestion)
            if (followUpQuestion === undefined) {
                removeFollowUpStep()
            } else {
                setAIFollowUpQuestion(followUpQuestion)
                addFollowUpStep()
            }
        },
    })

    return (
        <InspectorMultiStepFormContext.Provider
            value={{
                ctx: ctx,
                props: {
                    currentStep,
                    maxSteps,
                    nextSlide,
                    prevSlide,
                    setSlide,
                    gotoEnd,
                    removeFollowUpStep,
                },
                form: {
                    setInspectionImageUrl,
                    setInspectionImagePinpoint,
                    setInspectionDescription,
                    setInspectionContextDescription,
                    setInspectionEmail,
                    setRequestContact: setContactRequested,
                    setAIFollowUpQuestion,
                    submitForm,
                    formValues,
                },
                meta: {
                    value: meta,
                    setMetaValue: setMeta,
                },
                error: {
                    errors,
                    pushError,
                    popError,
                },
                uploadCareClient,
            }}
        >
            {slides[slideIndex]()}
        </InspectorMultiStepFormContext.Provider>
    )
}

export default InspectorMultiStepForm
