import React from 'react'
import { SupportMultiStepFormContext } from '../context'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../shared-steps/components/step-framework-wrapper'
import InspectorStepSubtitle from '../../../../../components/typography/inspector-step-subtitle'
import { useTranslation } from 'react-i18next'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import T from '../../../../../components/typography/t'
import styled from 'styled-components'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { Typography } from '@mui/material'
import ScrollWrapper from '../shared-steps/components/scroll-wrapper'

const StyledAccordionSummary = styled(AccordionSummary)`
    flex-direction: row-reverse;
    padding: 0 !important;

    & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
        transform: rotate(90deg);
    }
    & .MuiAccordionSummary-content,
    & .MuiAccordionSummary-content.Mui-expanded {
        margin-left: 0.5em;
    }
`

export const CostEstimationStep = ({
    onSelectedServicesChange,
    selectedProductCategory,
    initialValue,
    aiSuggestions,
    services,
}: {
    onSelectedServicesChange: (serviceIds: number[]) => void
    initialValue: number[]
    aiSuggestions: number[]
    selectedProductCategory: number
    services: any[]
}) => {
    const [t] = useTranslation()

    const onServiceSelect = (serviceId: number) => {
        if (initialValue.includes(serviceId)) {
            onSelectedServicesChange(initialValue.filter((id) => id !== serviceId))
        } else {
            onSelectedServicesChange([...initialValue, serviceId])
        }
    }

    const servicesInCategory = services.filter((service) =>
        !!selectedProductCategory ? service.productCategory === selectedProductCategory : true
    )

    const suggestedServices = servicesInCategory.filter((service) =>
        aiSuggestions.includes(service.id)
    )

    const maxPrice = Math.max(...suggestedServices.map((service) => service.price))
    const minPrice = Math.min(...suggestedServices.map((service) => service.price))
    const isPriceRange = maxPrice - minPrice > 0

    return (
        <>
            <InspectorStepTitle
                i18nKey="poc.support.warrantyCostEstimationStep.title"
                Icon={undefined}
            />
            <InspectorStepSubtitle
                mb={2}
                i18nKey="poc.support.warrantyCostEstimationStep.subtitle"
            />
            {isPriceRange ? (
                <Typography variant="h4" mb={2}>
                    {minPrice} - {maxPrice}€
                </Typography>
            ) : (
                <Typography variant="h4" mb={2}>
                    {maxPrice}€
                </Typography>
            )}
            <ScrollWrapper>
                <Accordion sx={{ boxShadow: 0 }} disableGutters elevation={0}>
                    <StyledAccordionSummary
                        expandIcon={<ArrowForwardIosSharpIcon />}
                        aria-controls="unknown-products-content"
                        id="unknown-products-header"
                    >
                        <T
                            i18nKey="poc.support.servicesStep.aiSuggestionLead"
                            style={{ whiteSpace: 'pre-line' }}
                        />
                    </StyledAccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                        <List
                            sx={{
                                width: '100%',
                                maxHeight: '100%',
                                overflow: 'auto',
                            }}
                        >
                            {suggestedServices.map((service) => (
                                <ListItem key={service.id} disablePadding>
                                    <ListItemText
                                        id={`checkbox-list-secondary-label-${service.id}`}
                                        primary={service.title}
                                        secondary={t('poc.support.servicesStep.price' as any, {
                                            price: service.price,
                                        })}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{ boxShadow: 0 }} disableGutters elevation={0}>
                    <StyledAccordionSummary
                        expandIcon={<ArrowForwardIosSharpIcon />}
                        aria-controls="unknown-products-content"
                        id="unknown-products-header"
                    >
                        <T
                            i18nKey="poc.support.servicesStep.remainingLead"
                            style={{ whiteSpace: 'pre-line' }}
                        />
                    </StyledAccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                        <List
                            sx={{
                                width: '100%',
                                maxHeight: '100%',
                                overflow: 'auto',
                            }}
                        >
                            {servicesInCategory.map((service) => (
                                <ListItem
                                    key={service.id}
                                    secondaryAction={
                                        <Checkbox
                                            edge="end"
                                            onChange={() => onServiceSelect(service.id)}
                                            checked={initialValue.includes(service.id)}
                                            inputProps={{
                                                'aria-labelledby': `checkbox-list-secondary-label-${service.id}`,
                                            }}
                                        />
                                    }
                                    disablePadding
                                >
                                    <ListItemButton onClick={() => onServiceSelect(service.id)}>
                                        <ListItemText
                                            id={`checkbox-list-secondary-label-${service.id}`}
                                            primary={service.title}
                                            secondary={t('poc.support.servicesStep.price' as any, {
                                                price: service.price,
                                            })}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            </ScrollWrapper>
        </>
    )
}

const CostEstimationStepContext = () => {
    const { form, ctx } = React.useContext(SupportMultiStepFormContext)
    return (
        <StepFrameworkWrapper
            navigationProps={{
                nextButtonDisabled: false,
            }}
        >
            <CostEstimationStep
                onSelectedServicesChange={form.setSelectedServices}
                selectedProductCategory={form.formValues.selectedProductCategory!}
                initialValue={form.formValues.selectedServices}
                aiSuggestions={form.formValues.aiSelectedServices}
                services={ctx.supportServices}
            />
        </StepFrameworkWrapper>
    )
}

export default CostEstimationStepContext
