import { logout } from 'wasp/client/auth'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import CompanyList from './cards/company-list/index'
import NewCompanyRepresentativeForm from './cards/new-company-representative-form'
import AdminPageWrapper from '../../../components/layout/admin-page-wrapper'
import UnverifiedUsers from './cards/unverified-users'
import T from '../../../components/typography/t'
import LanguageSwitcher from '../../../components/language-switcher'

const Dashboard = () => {
    return (
        <AdminPageWrapper>
            <Stack spacing={3} sx={{ width: '100%' }}>
                <NewCompanyRepresentativeForm />
                <UnverifiedUsers />
                <CompanyList />
            </Stack>

            <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                sx={{ width: '100%', mt: 4, mb: 2 }}
            >
                <Button onClick={logout}>
                    <T i18nKey="common.logout" />
                </Button>
                <LanguageSwitcher />
            </Stack>
        </AdminPageWrapper>
    )
}
export default Dashboard
