import React from 'react'
import useEnv from '../mixins/use-env'
import routeBuilder from '../routes'
import PageWrapper from '../components/layout/page-wrapper'
import PageContent from '../components/layout/page-content'
import NavBar from '../components/layout/NavBar'
import styled from 'styled-components'
import LogoWrapper from '../components/layout/navigation/logo-wrapper'
import AppSuite from '../../shared/app-suite'
import Logo from '../components/layout/navigation/logo'
import IconButton from '@mui/material/IconButton'
import SettingsIcon from '@mui/icons-material/Settings'

const AppSuiteNavigation = styled.nav`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

const AccountNavigation = styled.nav`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const Playground: React.FC = () => {
    const [idx, setIdx] = React.useState(0)
    const { isProd } = useEnv()
    if (isProd) {
        location.href = routeBuilder.base()
    }

    const next = () => {
        setIdx((prev) => (prev === 5 ? 0 : prev + 1))
    }

    const Children = () => (
        <>
            <button onClick={next}>next</button>
            <div
                style={{ background: 'red', height: '200px', width: '100px', margin: '50px' }}
            ></div>
            <div
                style={{ background: 'red', height: '200px', width: '100px', margin: '50px' }}
            ></div>
            <div
                style={{ background: 'red', height: '200px', width: '100px', margin: '50px' }}
            ></div>
            <div
                style={{ background: 'red', height: '200px', width: '100px', margin: '50px' }}
            ></div>
            <div
                style={{ background: 'red', height: '200px', width: '100px', margin: '50px' }}
            ></div>
        </>
    )

    if (idx === 0)
        return (
            <PageWrapper>
                Pagewrapper:
                <Children />
            </PageWrapper>
        )

    if (idx === 1)
        return (
            <>
                Plain
                <Children />
            </>
        )
    if (idx === 2)
        return (
            <div className="blurry-background">
                Blurry
                <Children />
            </div>
        )
    if (idx === 3)
        return (
            <PageContent $centered={false}>
                Pagecontent
                <Children />
            </PageContent>
        )
    if (idx === 4)
        return (
            <div className="blurry-background">
                <NavBar>
                    <AppSuiteNavigation>
                        <LogoWrapper href={routeBuilder.inspectorApp(0)}>
                            <Logo app={AppSuite.Inspector} isActive={false} />
                        </LogoWrapper>
                        <LogoWrapper
                            href={routeBuilder.reportApp(0)}
                            style={{ marginLeft: '10px' }}
                        >
                            <Logo app={AppSuite.Report} isActive={false} />
                        </LogoWrapper>
                        <LogoWrapper href={routeBuilder.userApp(0)} style={{ marginLeft: '10px' }}>
                            <Logo app={AppSuite.UserManagement} isActive={false} />
                        </LogoWrapper>
                    </AppSuiteNavigation>
                    <AccountNavigation>
                        <IconButton href={routeBuilder.companySettings(0)}>
                            <SettingsIcon />
                        </IconButton>
                    </AccountNavigation>
                </NavBar>
                <PageContent $centered={false}>
                    <Children />
                </PageContent>
            </div>
        )
    if (idx === 5)
        return (
            <>
                <NavBar>
                    <AppSuiteNavigation>
                        <LogoWrapper href={routeBuilder.inspectorApp(0)}>
                            <Logo app={AppSuite.Inspector} isActive={false} />
                        </LogoWrapper>
                        <LogoWrapper
                            href={routeBuilder.reportApp(0)}
                            style={{ marginLeft: '10px' }}
                        >
                            <Logo app={AppSuite.Report} isActive={false} />
                        </LogoWrapper>
                        <LogoWrapper href={routeBuilder.userApp(0)} style={{ marginLeft: '10px' }}>
                            <Logo app={AppSuite.UserManagement} isActive={false} />
                        </LogoWrapper>
                    </AppSuiteNavigation>
                    <AccountNavigation>
                        <IconButton href={routeBuilder.companySettings(0)}>
                            <SettingsIcon />
                        </IconButton>
                    </AccountNavigation>
                </NavBar>
                <PageContent $centered={false}>
                    <Children />
                </PageContent>
            </>
        )
}

export default Playground
