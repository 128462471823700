import React from 'react'
import { SupportMultiStepFormContext } from '../context'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../shared-steps/components/step-framework-wrapper'
import InspectorStepSubtitle from '../../../../../components/typography/inspector-step-subtitle'
import { useTranslation } from 'react-i18next'
import T from '../../../../../components/typography/t'
import styled from 'styled-components'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { Typography } from '@mui/material'
import ScrollWrapper from '../shared-steps/components/scroll-wrapper'
import StyledWarningAlert from '../../../../../components/alert/styled-warning-alert'

const StyledAccordionSummary = styled(AccordionSummary)`
    flex-direction: row-reverse;
    padding: 0 !important;

    & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
        transform: rotate(90deg);
    }
    & .MuiAccordionSummary-content,
    & .MuiAccordionSummary-content.Mui-expanded {
        margin-left: 0.5em;
    }
`

export const ClaimProbabilityStep = ({ claimProbability }: { claimProbability: number }) => {
    const [t] = useTranslation()

    return (
        <>
            <InspectorStepTitle i18nKey="poc.support.claimProbabilityStep.title" Icon={undefined} />
            <InspectorStepSubtitle mb={2} i18nKey="poc.support.claimProbabilityStep.subtitle" />

            <Typography variant="h4" mb={2}>
                {claimProbability}%
            </Typography>

            {claimProbability <= 50 && (
                <StyledWarningAlert severity="warning">
                    <T
                        i18nKey={'poc.support.claimProbabilityStep.alternativeSteps'}
                        style={{ whiteSpace: 'pre-line' }}
                        variant="body2"
                        color="grey.600"
                    />
                </StyledWarningAlert>
            )}
            <ScrollWrapper>
                <Accordion sx={{ boxShadow: 0 }} disableGutters elevation={0}>
                    <StyledAccordionSummary
                        expandIcon={<ArrowForwardIosSharpIcon />}
                        aria-controls="unknown-products-content"
                        id="unknown-products-header"
                    >
                        <T
                            i18nKey="poc.support.claimProbabilityStep.knowWhy"
                            style={{ whiteSpace: 'pre-line' }}
                        />
                    </StyledAccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                        <T
                            i18nKey={
                                claimProbability > 50
                                    ? 'poc.support.claimProbabilityStep.why'
                                    : 'poc.support.claimProbabilityStep.whyNot'
                            }
                            style={{ whiteSpace: 'pre-line' }}
                        />
                    </AccordionDetails>
                </Accordion>
            </ScrollWrapper>
        </>
    )
}

const ClaimProbabilityStepContext = () => {
    const { form, ctx } = React.useContext(SupportMultiStepFormContext)
    return (
        <StepFrameworkWrapper
            navigationProps={{
                nextButtonDisabled: false,
            }}
        >
            <ClaimProbabilityStep claimProbability={form.formValues.claimProbability ?? 0} />
        </StepFrameworkWrapper>
    )
}

export default ClaimProbabilityStepContext
