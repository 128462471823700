import { useQuery, getPublicCompany } from 'wasp/client/operations'
import { RouteComponentProps } from 'react-router'
import CustomerPageWrapper from '../../../../components/layout/customer-page-wrapper'
import CompanyProductList from './company-product-list'
import SubtleFullPageLoadingScreen from '../../../../components/subtle-full-page-loading-screen'
import ColabLogos from '../../../../components/colab-logos'
import T from '../../../../components/typography/t'
import theme from '../../../../theme'
import IntroOverlay from './intro-overlay'
import useDialog from '../../../../mixins/use-dialog'
import useInspectorEmailcookie from '../../email-cookie'

const CompanyProductListPage = (props: RouteComponentProps<{ companyUuid: string }>) => {
    const companyUuid = props.match.params.companyUuid
    const [getEmailAddressFromCookies] = useInspectorEmailcookie()
    const isRegisteredTester = !!getEmailAddressFromCookies()
    const { isOpen, open, close } = useDialog(!isRegisteredTester)
    const {
        data: companySettings,
        isFetching,
        error,
    } = useQuery(getPublicCompany, { uuid: companyUuid })

    if (error) return <div> Error! </div>

    return (
        <SubtleFullPageLoadingScreen isLoading={isFetching}>
            {!!companySettings && (
                <CustomerPageWrapper>
                    <ColabLogos companyLogoUrl={companySettings!.logoUrl} scale={0.75} />
                    <T
                        i18nKey="publicCompany.profile.title"
                        component="h1"
                        variant="h4"
                        color={theme.palette.primary.main}
                        mt={2}
                        mb={2}
                    ></T>
                    <T
                        i18nKey="publicCompany.profile.lead"
                        component="p"
                        variant="body1"
                        textAlign="center"
                        mb={4}
                    ></T>
                    <CompanyProductList products={companySettings!.products} />
                    <T
                        i18nKey="publicCompany.profile.help"
                        component="a"
                        variant="body2"
                        textAlign="center"
                        mt={2}
                        mb={2}
                        onClick={open}
                    />
                    <IntroOverlay isOpen={isOpen} close={close} />
                </CustomerPageWrapper>
            )}
        </SubtleFullPageLoadingScreen>
    )
}

export default CompanyProductListPage
