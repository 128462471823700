import { createTheme, Theme, responsiveFontSizes } from '@mui/material'

const titleFontOptions = {
    fontFamily: "'Open Sans', sans-serif",
}
const baseTheme = responsiveFontSizes(createTheme())

const theme = createTheme({
    typography: {
        h1: {
            ...titleFontOptions,
            fontSize: '3rem',
            [baseTheme.breakpoints.down('md')]: {
                fontSize: '2.5rem',
            },
            [baseTheme.breakpoints.down('sm')]: {
                fontSize: '2rem',
            },
            fontWeight: 400,
        },
        h2: {
            ...titleFontOptions,
            fontSize: '2rem',
            fontWeight: 500,
        },
        h3: {
            ...titleFontOptions,
            fontSize: '1.5rem',
            [baseTheme.breakpoints.down('sm')]: {
                fontSize: '1.33rem',
            },
            fontWeight: 500,
        },
        h4: { ...titleFontOptions },
        h5: { ...titleFontOptions },
        body1: {
            fontSize: '1rem',
        },
        body2: {
            fontSize: '0.875rem',
        },
        button: {
            fontSize: '14px',
        },
    },
    palette: {
        primary: {
            light: '#CCB3E5',
            main: '#380e4b',
            dark: '#481D73',
            contrastText: '#f3f3f3',
        },

        getCrosshairColors: () => ({ stroke: '#E4FF03', fill: '#f20fcc' }),
        yellow: { main: '#E4FF03' },
        inspector: { main: '#FF00BF' },
        report: { main: '#007BFF' },
        userManagement: { main: '#F2A63B' },
        support: { main: '#60E033' },
        secondary: {
            light: '#ffb2e3',
            main: '#ff00bf',
            dark: '#ca009d',
            contrastText: '#000',
        },
        success: {
            light: '#B3E5C1',
            main: '#4EC06E',
            dark: '#1D7335',
            contrastText: '#000',
        },
        warning: {
            light: '#EDD5AB',
            main: '#E7A535',
            dark: '#A16E16',
            contrastText: '#000',
        },
        error: {
            light: '#E5B3B3',
            main: '#C04E4E',
            dark: '#731D1D',
            contrastText: '#fff',
        },
        info: {
            light: '#B3CDE5',
            main: '#4E89C0',
            dark: '#1D4973',
            contrastText: '#000',
        },
        grey: {
            50: '#F1F0F2',
            100: '#DCDADD',
            200: '#B5B1BA',
            300: '#9A969D',
            400: '#655F6B',
            500: '#49424F',
            600: '#302A36',
            700: '#16131A',
            800: '#020203',
        },
    } as any,
    components: {
        // Name of the component
        MuiButton: {
            defaultProps: {
                variant: 'outlined',
            },
            styleOverrides: {
                // The props to change the default for.
                root: {
                    padding: '8px 24px',
                    '@media (max-width: 600px)': {
                        padding: '8px 16px',
                    },
                    borderRadius: 24,
                    textTransform: 'initial',
                    fontWeight: '400',
                },
            },
        },
        MuiSwipeableDrawer: {
            defaultProps: {
                anchor: 'bottom',
            },
        },
        MuiDrawer: {
            defaultProps: {
                anchor: 'bottom',
            },
        },
        MuiTab: {
            styleOverrides: {
                root: ({ theme }: { theme: Theme }) => ({
                    borderRadius: '24px',
                    zIndex: 1,
                    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,

                    [theme.breakpoints.down('md')]: {
                        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
                        minHeight: '40px',
                    },

                    '&:hover': {
                        color: theme.palette.primary.main,
                    },

                    '&.Mui-selected': {
                        color: theme.palette.primary.contrastText,
                    },
                }),
            },
        },

        MuiTabs: {
            styleOverrides: {
                root: ({ theme }: { theme: Theme }) => ({
                    outline: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: '24px',

                    [theme.breakpoints.down('md')]: {
                        minHeight: '40px',
                    },

                    '& .MuiTabs-indicator ': {
                        color: theme.palette.primary.main,
                        height: '100%',
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: '24px',
                    },
                }),
            },
        },
    },
    spacing: (factor: number) => `${0.5 * factor}rem`,
})

export default theme
