import guides from '../ufixit/data/guides'
import whitelabels, { Whitelabel, brkWhitelabel } from '../support/multi-step/data/whitelabel'
import { RouteComponentProps } from 'react-router'
import {
    ThemeProvider,
    createTheme,
    styled,
    Stack,
    Typography,
    Theme,
    Chip,
    Button,
} from '@mui/material'
import {
    Page,
    Header,
    WidthContainer,
    BrandLogo,
    Content,
    Footer,
    PBB,
    PoweredBy,
    PoweredByBrakeable,
} from '../layout'
import { findProductTypeById } from '../support/multi-step/data/products/helpers'
import CrosshairSvg from '../../../assets/img/crosshair.svg'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import LaptopIcon from '@mui/icons-material/Laptop'
import { useQuery, getPOCEventsByBrand } from 'wasp/client/operations'
import AnalysatorList from './analysator-list'

const analytics = [
    {
        anonymousUserId: '23432423',
        timestamp: '22.12.2024',
        isMobile: true,
        userInput: {
            selectedProduct: 111,
            description: 'My zipper slider is stuck',
            uploadedPhotoUrl: 'https://ucarecdn.com/3f149c00-dc75-4753-b549-e4deb8fd9ec5/',
            pinpoint: { x: 0.5014577259475219, y: 0.41690962099125367 },
        },
        resources: {
            selectedComponent: 'Zipper Slider, Front Zipper',
            availableRepairGuides: [
                169514, 169517, 169519, 169520, 169521, 169522, 169525, 169526, 169527, 169531,
                169940, 170249, 69099, 69101, 69102, 69105, 69115, 69137, 69138, 69141, 69150,
                69155, 74517, 74532, 69102, 74532,
            ],
        },
        result: { aiSelectedRepairGuides: [172345, 173073] },
    },
]

const ImageWrapper = styled('div')`
    width: 150px;
    height: 150px;
    position: relative;
    min-height: 0; // to shrink element to fit image inside flexbox
    flex-shrink: 1;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
`

const ProductImage = styled('img')`
    max-width: 100%;
    max-height: 100%;
    position: relative;
`

const Crosshair = styled('img')`
    width: 30px;
    height: 30px;
    position: absolute;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 100;
`

const ListEntry = styled('li')`
    display: flex;
    flex-direction: row;
    height: 200px;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    border-radius: 24px;
    padding: 0.1em 1em;
    overflow: hidden;
    gap: 1em;
`

const AnalysatorPage = (props: RouteComponentProps<{ brandId: string }>) => {
    const brandId = props.match.params.brandId
    const whitelabel: Whitelabel | undefined = whitelabels.find((w) => w.id === brandId)

    if (!whitelabel) {
        return <AnalysatorList />
    }

    const { data: analytics, error } = useQuery(getPOCEventsByBrand, { brandId })

    if (error) {
        return 'error'
    }
    if (!analytics) {
        return 'loading'
    }

    return (
        <ThemeProvider
            theme={(theme: Theme) =>
                createTheme({
                    ...theme,
                    palette: {
                        ...theme?.palette,
                        ...whitelabel.theme.palette,
                    },
                } as any)
            }
        >
            <Page>
                <Header>
                    <WidthContainer sx={{ justifyContent: 'space-between' }}>
                        <BrandLogo src={whitelabel.logo} alt={whitelabel.name} />
                    </WidthContainer>
                </Header>
                <Content>
                    <WidthContainer sx={{ maxWidth: 1200 }}>
                        <Stack direction="row" mb={2} gap={1} alignItems="center">
                            <Button component="a" href="/analysator/list">
                                Back
                            </Button>
                            <Chip
                                label={`${analytics.uniquePageVisitors} Unique Visitors`}
                                color="primary"
                            />
                            <Chip
                                label={`${analytics.totalPageVisits} Page Views`}
                                color="primary"
                            />
                        </Stack>
                        <Stack gap={1}>
                            {analytics.submissions.map((entry) => {
                                const product = findProductTypeById(
                                    entry.userInput.selectedProduct as any,
                                    whitelabel.productCategoryTree
                                )
                                return (
                                    <ListEntry key={entry.id}>
                                        <div>
                                            <ImageWrapper>
                                                <ProductImage
                                                    src={
                                                        product?.views[
                                                            entry.userInput.selectedProductView ?? 0
                                                        ].sketchUrl
                                                    }
                                                />
                                                <Crosshair
                                                    src={CrosshairSvg}
                                                    style={{
                                                        left:
                                                            entry.userInput.pinpoint.x * 100 + '%',
                                                        top: entry.userInput.pinpoint.y * 100 + '%',
                                                    }}
                                                />
                                            </ImageWrapper>
                                            <Typography sx={{ fontSize: '.8em' }}>
                                                {entry.resources.selectedComponent}
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography sx={{ fontStyle: 'italic' }}>
                                                "{entry.userInput.description}"
                                            </Typography>
                                            <Stack direction="row" gap={1} alignItems="center">
                                                <Typography sx={{ color: 'grey.400' }}>
                                                    {entry.anonymousUserId}
                                                    {entry.isMobile ? (
                                                        <PhoneAndroidIcon fontSize="small" />
                                                    ) : (
                                                        <LaptopIcon fontSize="small" />
                                                    )}
                                                </Typography>
                                            </Stack>
                                            <Typography sx={{ color: 'grey.400' }}>
                                                {entry.timestamp}
                                            </Typography>
                                        </div>
                                        <img src={entry.userInput.uploadedPhotoUrl} />
                                        <div>
                                            <Typography sx={{ fontWeight: 'bold' }}>
                                                Suggested guides:
                                            </Typography>
                                            <ul>
                                                {entry.result.aiSelectedRepairGuides.map((g) => (
                                                    <li key={g}>{guides[g].title}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </ListEntry>
                                )
                            })}
                        </Stack>
                    </WidthContainer>
                </Content>
                <Footer>
                    <WidthContainer sx={{ justifyContent: 'flex-end' }}>
                        <PBB>
                            <PoweredBy>Powered by</PoweredBy>{' '}
                            <PoweredByBrakeable>BRAKEABLE</PoweredByBrakeable>
                        </PBB>
                    </WidthContainer>
                </Footer>
            </Page>
        </ThemeProvider>
    )
}

export default AnalysatorPage
