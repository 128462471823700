import React from 'react'
import styled from 'styled-components'

const ListScrollWrapper = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
    flex-shrink: 1;
    max-height: 100%;

    &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        height: 100px;
        width: 100%;
        pointer-events: none;
        box-shadow: inset 0 -50px 18px -18px #fff;
    }
`

const InnerScrollWrapper = styled.div`
    height: 100%;
    overflow: auto;
    padding-bottom: 50px;
`

export default ({ children }: { children: React.ReactNode }) => {
    return (
        <ListScrollWrapper>
            <InnerScrollWrapper>{children}</InnerScrollWrapper>
        </ListScrollWrapper>
    )
}
