import React from 'react'
import TextField from '@mui/material/TextField'
import { InspectorMultiStepFormContext } from '../../inspector-multi-step-form'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../components/step-framework-wrapper'
import InspectorStepSubtitle from '../../../../../components/typography/inspector-step-subtitle'
import { useTranslation } from 'react-i18next'
import DescriptionIcon from '../../../../../assets/img/inspector-icons/description-icon.svg'
import { useCopilotChat } from '@copilotkit/react-core'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export const DescriptionStep = ({
    onDescriptionChange,
    initialDescription,
}: {
    onDescriptionChange: (description: string) => void
    initialDescription?: string
}) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle
                mb={2}
                i18nKey="inspector.mobile.description.title"
                Icon={DescriptionIcon}
            />
            <InspectorStepSubtitle mb={2} i18nKey="inspector.mobile.description.description" />
            <TextField
                multiline
                fullWidth
                defaultValue={initialDescription}
                label={t('inspector.mobile.description.label' as any)}
                placeholder={t('inspector.mobile.description.placeholder' as any)}
                minRows={5}
                maxRows={15}
                onChange={(e) => onDescriptionChange(e.target.value)}
            ></TextField>
        </>
    )
}

const DescriptionStepContext = () => {
    const { props, form } = React.useContext(InspectorMultiStepFormContext)
    const [isLoading, setIsloading] = React.useState(false)
    const { append } = useCopilotChat({})
    React.useEffect(() => {
        props.removeFollowUpStep()
    }, [])

    return (
        <StepFrameworkWrapper
            navigationProps={{
                nextButtonDisabled: !form.formValues.inspectionDescription,
                onValidNextClick: async () => {
                    setIsloading(true)
                    try {
                        await append({
                            id: Math.random() + '',
                            role: 'user',
                            content: `
                            The user described an issue below. We want to know the following details about it:
                            - Where was the product mainly used and what for?
                            - How regularly was the product in use?
                            - What exactly is the issue at hand?
                            If the user did not answer all the questions, formulate a follow-up question in their language, inviting them to provide the missing information.
                            The question should be short and concise. If the user provided all the necessary information, you can skip the follow-up question.
                            ---
                            The user has the following issue: "${form.formValues.inspectionDescription}".
                            `,
                        })

                        return
                    } catch (error) {
                        setIsloading(false)
                        console.error(error)
                        return
                    } finally {
                        setIsloading(false)
                        return
                    }
                },
            }}
        >
            <DescriptionStep
                onDescriptionChange={form.setInspectionDescription}
                initialDescription={form.formValues.inspectionDescription}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </StepFrameworkWrapper>
    )
}

export default DescriptionStepContext
