import React from 'react'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { InspectorMultiStepFormContext } from '../../inspector-multi-step-form'
import FileUploadProgress from '../components/file-upload-progress'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../components/step-framework-wrapper'
import InspectorStepSubtitle from '../../../../../components/typography/inspector-step-subtitle'
import { useTranslation } from 'react-i18next'
import useInspectorEmailcookie from '../../../email-cookie'
import ContactIcon from '../../../../../assets/img/inspector-icons/contact-icon.svg'
import T from '../../../../../components/typography/t'

export const ContactStep = ({
    onEmailChange,
    onRequestContactChange: onRequestContactChange,
    fileUploadProgress = 0,
    initialEmail,
    allowContactRequests,
}: {
    onEmailChange: (mail: string) => void
    onRequestContactChange: (flag: boolean) => void
    fileUploadProgress: number
    initialEmail?: string
    allowContactRequests: boolean
}) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle
                mb={2}
                i18nKey="inspector.mobile.contact.title"
                Icon={ContactIcon}
            />
            <InspectorStepSubtitle mb={2} i18nKey="inspector.mobile.contact.description" />
            <TextField
                fullWidth
                label={t('inspector.mobile.contact.label' as any)}
                placeholder={t('inspector.mobile.contact.placeholder' as any)}
                type="email"
                defaultValue={initialEmail}
                onChange={(e) => onEmailChange(e.target.value)}
            ></TextField>
            {allowContactRequests && (
                <FormControlLabel
                    sx={{ mt: 3 }}
                    control={
                        <Checkbox
                            onChange={(e) => onRequestContactChange(e.target.checked)}
                            data-testid="contact-request-checkbox"
                        />
                    }
                    label={t('inspector.mobile.contact.requestContact' as any)}
                />
            )}
            {fileUploadProgress < 1 && (
                <>
                    <T i18nKey="inspector.mobile.contact.photoUploadLabel" mt={3} mb={1} />
                    <FileUploadProgress value={fileUploadProgress} />
                </>
            )}
        </>
    )
}

const ContactStepContext = () => {
    const { props, meta, form, ctx } = React.useContext(InspectorMultiStepFormContext)
    const [_, saveInspectorEmailInCookies] = useInspectorEmailcookie()

    const submitForm = async () => {
        await form.submitForm()
        saveInspectorEmailInCookies(form.formValues.inspectionEmail!)
        props.nextSlide()
    }

    const imageUploaded = !!form.formValues.inspectionImageUrl
    const emailProvided = !!form.formValues.inspectionEmail
    const contactRequested = form.formValues.contactRequested
    const emailRequired = contactRequested || ctx.isEmailMandatory

    const nextButtonEnabled = imageUploaded && (!emailRequired || emailProvided)

    return (
        <StepFrameworkWrapper
            navigationProps={{
                nextButtonDisabled: !nextButtonEnabled,
                onValidNextClick: submitForm,
                nextButtonLabelKey: 'inspector.mobile.navigation.submit',
            }}
        >
            <ContactStep
                onEmailChange={form.setInspectionEmail}
                onRequestContactChange={form.setRequestContact}
                initialEmail={form.formValues.inspectionEmail}
                fileUploadProgress={meta.value?.fileUpload?.progress}
                allowContactRequests={ctx.allowContactRequests}
            />
        </StepFrameworkWrapper>
    )
}

export default ContactStepContext
