import React from 'react'
import CompanyId from '../../../../shared/types/company/id'
import nullableParseInt from '../../../mixins/nullable-parse-int'
import Inspector from './inspector'

const InspectorPage = ({ match }: { match: any }) => {
    const { companyId } = match.params
    const compId = nullableParseInt(companyId) as CompanyId | undefined
    return <Inspector companyId={compId} />
}
export default InspectorPage
