import React from 'react'
import ReactGA from 'react-ga4'
import styled from 'styled-components'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import theme from '../../../../../theme'
import useDialog from '../../../../../mixins/use-dialog'
import T from '../../../../../components/typography/t'

const intructionUrl =
    'https://docs.prontoforms.com/1374411/Content/BuildAndManageForms/CustomerFeedbackForms/CameraControl.htm'

const Label = styled.label`
    margin-top: 1.5rem;
    display: block;
    background: ${theme.palette.primary.main};
    color: #e4ff03;
    padding: 1rem;
    border-radius: 0.5rem;
    text-align: center;
    cursor: pointer;
    font-size: 1.2em;

    input {
        opacity: 0;
        position: absolute;
        pointer-events: none;
        width: 0;
    }
`

const StyledDialog = styled(Dialog)`
    width: 100%;
    border: 1px solid red;
`

const PhotoInput: React.FC<{
    chosePhoto: (e: React.ChangeEvent<HTMLInputElement>) => void
    children: React.ReactNode
    allowGalleryUpload: boolean
}> = ({ chosePhoto, children, allowGalleryUpload }) => {
    const { isOpen, open, close } = useDialog()
    const inputRef = React.useRef<HTMLInputElement>(null)

    React.useEffect(() => {
        if (inputRef.current) {
            inputRef.current.addEventListener('cancel', () => {
                ReactGA.event({
                    category: 'Inspector',
                    action: 'Photo cancelled',
                })
                open()
            })
        }
    }, [isOpen])

    const dismiss = () => {
        close()
        ReactGA.event({
            category: 'Inspector',
            action: 'Camera permission dismissed',
        })
    }

    const onShowMe = () => {
        close()
        ReactGA.event({
            category: 'Inspector',
            action: 'Camera permission show me',
        })
    }

    return (
        <>
            <Label>
                <span>{children}</span>
                <input
                    type="file"
                    accept="image/*"
                    capture={allowGalleryUpload ? undefined : 'environment'}
                    onChange={chosePhoto}
                    ref={inputRef}
                    data-testid="photo-input"
                />
            </Label>
            <StyledDialog open={isOpen} onClose={close}>
                <DialogContent>
                    <T
                        i18nKey="inspector.mobile.photo.cancelled.description"
                        component="p"
                        variant="body1"
                        textAlign="center"
                    />
                    <DialogActions>
                        <Button onClick={dismiss}>
                            <T i18nKey="inspector.mobile.photo.cancelled.dismiss" />
                        </Button>
                        <Button
                            component="a"
                            onClick={onShowMe}
                            href={intructionUrl}
                            target="_blank"
                            variant="contained"
                        >
                            <T i18nKey="inspector.mobile.photo.cancelled.showMe" />
                        </Button>
                    </DialogActions>
                </DialogContent>
            </StyledDialog>
        </>
    )
}

export default PhotoInput
