export type SupportService = {
    id: number
    title: string
    description: string
    productCategory: number
    price: number
}

export const supportServicesEn = [
    {
        id: 1,
        title: 'Replace Zipper Slider',
        description:
            'Sometimes the zipper on your product is beyond repair. In that case, the only solution is to install a new zipper slider. This applies at least to all cases where the...',
        productCategory: 1,
        price: 16.5,
    },
    {
        id: 2,
        title: 'Repair Zipper Slider',
        description:
            "Is the zipper on your product not staying closed and opening again from the bottom after you close it? That's annoying, but usually not a big repair. Since this...",
        productCategory: 1,
        price: 5.5,
    },
    {
        id: 3,
        title: 'Repair Zipper Slider',
        description:
            "Is the zipper on your GORE-TEX pants not staying closed and opening again from the bottom after you close it? That's annoying, but usually not a big repair. Since this repair...",
        productCategory: 2,
        price: 5.5,
    },
    {
        id: 4,
        title: 'New Pocket Zipper',
        description:
            'In all cases where the zipper cannot be easily repaired, the only option left is to replace the defective zipper on your product. Only then can we...',
        productCategory: 1,
        price: 42.95,
    },
    {
        id: 5,
        title: 'New Front Zipper',
        description:
            'Do you often struggle with your front zipper? Then it might be a good idea to replace it. The numerous outdoor adventures certainly take their toll...',
        productCategory: 1,
        price: 72.95,
    },
    {
        id: 6,
        title: 'New Front Zipper',
        description:
            'Do you often struggle with your pants zipper? Then it might be a good idea to replace it. The numerous outdoor adventures certainly take their toll...',
        productCategory: 2,
        price: 27.95,
    },
    {
        id: 7,
        title: 'Renew Drawstring',
        description:
            "Does the elastic or drawstring on your product need urgent renewal? That's no problem for us, and we are happy to take care of it for you. We carefully open the waistband of your...",
        productCategory: 1,
        price: 27.95,
    },
    {
        id: 8,
        title: 'Renew Velcro',
        description:
            "Sometimes your Velcro isn't functioning properly anymore. We are happy to renew a Velcro strip on your product. To renew the Velcro, we open the seam at the corresponding...",
        productCategory: 1,
        price: 27.95,
    },
    {
        id: 9,
        title: 'Renew Velcro',
        description:
            "Sometimes your Velcro isn't functioning properly anymore. We are happy to renew a Velcro strip on your GORE-TEX pants. To renew the Velcro, we open the seam at the corresponding place...",
        productCategory: 2,
        price: 27.95,
    },
    {
        id: 10,
        title: 'Sew and Tape Tear',
        description:
            "Have you just returned from a wild outdoor tour and now your product has an unsightly tear? That's annoying, but no reason to be sad for long! We can provide quick relief. And that...",
        productCategory: 1,
        price: 32.95,
    },
    {
        id: 11,
        title: 'Sew and Tape Tear',
        description:
            "Have you just returned from a wild outdoor tour and now your GORE-TEX pants have an unsightly tear? That's annoying, but no reason to be sad for long! We can provide quick relief. And that...",
        productCategory: 2,
        price: 32.95,
    },
    {
        id: 12,
        title: 'Re-glue Seams',
        description:
            'Sometimes, due to heavy use, the seams of your product may no longer be completely sealed. However, since the garments are usually still completely intact,...',
        productCategory: 1,
        price: 42.95,
    },
    {
        id: 13,
        title: 'Repair Lining',
        description:
            'Sometimes the inner lining of your product, which is usually protected, gets damaged and needs repair. We offer you this professional service and restore the full...',
        productCategory: 1,
        price: 22.95,
    },
    {
        id: 14,
        title: 'Repair Lining',
        description:
            'Sometimes the inner lining of your GORE-TEX pants, which is usually protected, gets damaged and needs repair. We offer you this professional service and restore the full...',
        productCategory: 2,
        price: 22.95,
    },
    {
        id: 15,
        title: 'Insert Fabric Piece',
        description:
            'Is the fabric on your product damaged? Fortunately, there is no longer any reason to dispose of the beautiful garment. Thanks to modern processing techniques, defective fabric pieces...',
        productCategory: 1,
        price: 67.95,
    },
    {
        id: 16,
        title: 'Insert Fabric Piece',
        description:
            'Is the fabric on your GORE-TEX pants damaged? Fortunately, there is no longer any reason to dispose of the beautiful garment. Thanks to modern processing techniques, defective fabric pieces...',
        productCategory: 2,
        price: 67.95,
    },
    {
        id: 17,
        title: 'Re-glue Seams',
        description:
            'Sometimes, due to heavy use, the seams of your GORE-TEX pants may no longer be completely sealed. However, since the garments are usually still completely intact,...',
        productCategory: 2,
        price: 42.95,
    },
    {
        id: 18,
        title: 'New Vent Zipper',
        description:
            'In all cases where the zipper can no longer be easily repaired, the only option left is to replace the defective zipper on the product. Only then can...',
        productCategory: 1,
        price: 52.95,
    },
]

export const supportServicesDe = [
    {
        id: 1,
        title: 'Reißverschlussschlitten tauschen',
        description:
            'Manchmal ist der Zipper am Reißverschluss deines Produkts nicht mehr zu reparieren. Dann hilft nur noch das Einsetzen eines neuen Zippers. Dies gilt zumindest für alle Fälle, in denen die...',
        productCategory: 1,
        price: 16.5,
    },
    {
        id: 2,
        title: 'Reißverschlussschlitten reparieren',
        description:
            'Bleibt der Reißverschluss deines Produkts nicht mehr geschlossen und öffnet sich nach dem Schließen wieder von unten? Das ist ärgerlich, aber meistens keine große Reparatur. Da diese...',
        productCategory: 1,
        price: 5.5,
    },
    {
        id: 3,
        title: 'Reißverschlussschlitten reparieren',
        description:
            'Bleibt der Reißverschluss Deiner GORE-TEX Hose nicht mehr geschlossen und öffnet sich nach dem Schließen wieder von unten? Das ist ärgerlich, aber meistens keine große Reparatur. Da diese Reparatur...',
        productCategory: 2,
        price: 5.5,
    },
    {
        id: 4,
        title: 'neuer Taschenreißverschluss',
        description:
            'In allen Fällen, in denen der Reißverschluss auf einfache Weise nicht mehr zu reparieren ist, bleibt nur noch der Austausch des defekten Reißverschlusses an deinem Produkt. Nur so können wir...',
        productCategory: 1,
        price: 42.95,
    },
    {
        id: 5,
        title: 'neuer Frontreißverschluss',
        description:
            'Hast Du häufig mit deinem Frontreißverschluss zu kämpfen? Dann könnte es gut sein, dass dieser einmal ausgetauscht werden sollte. Die zahlreichen Outdoor-Abenteuer setzen ihm natürlich gehörig zu...',
        productCategory: 1,
        price: 72.95,
    },
    {
        id: 6,
        title: 'neuer Frontreißverschluss',
        description:
            'Hast Du häufig mit deinem Hosenreißverschluss zu kämpfen? Dann könnte es gut sein, dass dieser einmal ausgetauscht werden sollte. Die zahlreichen Outdoor-Abenteuer setzen ihm natürlich gehörig zu...',
        productCategory: 2,
        price: 27.95,
    },
    {
        id: 7,
        title: 'Kordelzug erneuern',
        description:
            'Der Gummi- oder Kordelzug an deinem Produkt müsste mal dringend erneuert werden? Für uns kein Problem und wir erledigen das gerne für Dich. Wir öffnen dafür vorsichtig den Bund Deiner...',
        productCategory: 1,
        price: 27.95,
    },
    {
        id: 8,
        title: 'Klett erneuern',
        description:
            "Manchmal hakt's an Deinem Klettverschluss nicht mehr so richtig. Dann erneuern wir gerne ein Klettband deines Produkts. Zum Erneuern des Kletts öffnen wir die Naht an der entsprechenden...",
        productCategory: 1,
        price: 27.95,
    },
    {
        id: 9,
        title: 'Klett erneuern',
        description:
            "Manchmal hakt's an Deinem Klettverschluss nicht mehr so richtig. Dann erneuern wir gerne ein Klettband Deiner GORE-TEX Hose. Zum Erneuern des Kletts öffnen wir die Naht an der entsprechenden Stelle...",
        productCategory: 2,
        price: 27.95,
    },
    {
        id: 10,
        title: 'Riss nähen und tapen',
        description:
            'Wilde Outdoor-Tour hinter Dir und jetzt hat dein Produkt einen unschönen Riss? Das ist ärgerlich, aber kein Grund, lange traurig zu sein! Wir können schnelle Abhilfe schaffen. Und das...',
        productCategory: 1,
        price: 32.95,
    },
    {
        id: 11,
        title: 'Riss nähen und tapen',
        description:
            'Wilde Outdoor-Tour hinter Dir und jetzt hat Deine GORE-TEX Hose einen unschönen Riss? Das ist ärgerlich, aber kein Grund, lange traurig zu sein! Wir können schnelle Abhilfe schaffen. Und das...',
        productCategory: 2,
        price: 32.95,
    },
    {
        id: 12,
        title: 'Nähte neu verkleben',
        description:
            'Manchmal kann es vorkommen, dass aufgrund starker Beanspruchung, die Nähte deines Produktes nicht mehr ganz dicht sind. Da die Kleidungsstücke meistens aber noch vollkommen in Ordnung sind,...',
        productCategory: 1,
        price: 42.95,
    },
    {
        id: 13,
        title: 'Futter reparieren',
        description:
            'Manchmal erwischt es auch das eigentlich geschützt liegende Innenfutter deines Produktes und eine Reparatur wird notwendig. Wir bieten Dir diesen fachgerechten Service und stellen die volle...',
        productCategory: 1,
        price: 22.95,
    },
    {
        id: 14,
        title: 'Futter reparieren',
        description:
            'Manchmal erwischt es auch das eigentlich geschützt liegende Innenfutter Deiner GORE-TEX Hose und eine Reparatur wird notwendig. Wir bieten Dir diesen fachgerechten Service und stellen die volle...',
        productCategory: 2,
        price: 22.95,
    },
    {
        id: 15,
        title: 'Stoffteil einsetzen',
        description:
            'Ist der Stoff an deinem Produkt beschädigt? Heutzutage zum Glück kein Grund mehr, das schöne Kleidungsstück zu entsorgen. Dank modernster Verarbeitungsverfahren können defekte Stoffteile...',
        productCategory: 1,
        price: 67.95,
    },
    {
        id: 16,
        title: 'Stoffteil einsetzen',
        description:
            'Ist der Stoff an Deiner GORE-TEX Hose beschädigt? Heutzutage zum Glück kein Grund mehr, das schöne Kleidungsstück zu entsorgen. Dank modernster Verarbeitungsverfahren können defekte Stoffteile...',
        productCategory: 2,
        price: 67.95,
    },
    {
        id: 17,
        title: 'Nähte neu verkleben',
        description:
            'Manchmal kann es vorkommen, dass aufgrund starker Beanspruchung, die Nähte Deiner GORE-TEX Hose nicht mehr ganz dicht sind. Da die Kleidungsstücke meistens aber noch vollkommen in Ordnung sind,...',
        productCategory: 2,
        price: 42.95,
    },
    {
        id: 18,
        title: 'neuer Lüftungsreißverschluss',
        description:
            'In allen Fällen, in denen der Reißverschluss auf einfache Weise nicht mehr zu reparieren ist, bleibt nur noch der Austausch des defekten Reißverschlusses an Produkt. Nur so können...',
        productCategory: 1,
        price: 52.95,
    },
]

export default supportServicesEn
