export const POCPageViewEvent = 'POC_Pageview'
export const POCSubmissionEvent = 'POC_Submission'

export const InspectorPageViewMobileEvent = 'Inspector_Pageview:Mobile'
export const InspectorPageViewDesktopEvent = 'Inspector_Pageview:Desktop'
export const UnknownUserId = 'unknown'
export const ClientDevice = {
    Mobile: 'mobile',
    Desktop: 'desktop',
}
