import styled from 'styled-components'
import theme from '../../../../../../../theme'

const StepContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 500px;
    padding: 32px 16px 112px;

    ${theme.breakpoints.down('sm')} {
        padding: 20px 10px 100px;
    }
`

export default StepContainer
