import React from 'react'
import { styled, useTheme } from '@mui/material'
import Coord from '../../../../../../../shared/types/common/coord'
// @ts-ignore
import CrosshairSvg from '../../../../../../assets/img/crosshair.svg?react'
import Lottie from 'lottie-react'
import clickAnimation from './lottie-click-animation'
import { ProductDetail } from '../../data/products/types'

const ImageWrapper = styled('div')`
    max-width: 100%;
    max-height: 100%;
    position: relative;
    min-height: 0; // to shrink element to fit image inside flexbox
    flex-shrink: 1;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
`

const ProductImage = styled('img')`
    max-width: 100%;
    max-height: 100%;
    position: relative;
`

const Crosshair = styled(CrosshairSvg)`
    width: 60px;
    height: 60px;
    position: absolute;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 100;
`

const PinpointNudge = styled(Lottie)`
    width: 100px;
    height: 100px;
    position: absolute;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 100;
    top: 50%;
    left: 50%;
`

const Pinpoint = ({
    productImageUrl,
    onPinpointChanged,
    setSelectedComponent,
    initialCoords = { x: -1000, y: -1000 },
    productDetails,
}: {
    productImageUrl: string
    onPinpointChanged: (coord: Coord) => void
    setSelectedComponent: (component?: string) => void
    initialCoords?: Coord
    productDetails: ProductDetail[]
}) => {
    const theme = useTheme()
    const [coordinates, setCoordinates] = React.useState<Coord>(initialCoords)
    const [imageSize, setImageSize] = React.useState<{ width: number; height: number }>({
        width: 0,
        height: 0,
    })

    const imageRef = React.useRef<HTMLImageElement>(null)

    const updateCoordinates = (coord: Coord) => {
        if (imageRef.current == null) {
            return
        }
        const imageRect = imageRef.current.getBoundingClientRect()

        const calculatedCoordinates = {
            x: Math.floor(Math.min(Math.max(coord.x - imageRect.x, 0), imageRect.width)),
            y: Math.floor(Math.min(Math.max(coord.y - imageRect.y, 0), imageRect.height)),
        }

        const proportionalCoordinates = {
            x: calculatedCoordinates.x / imageRect.width,
            y: calculatedCoordinates.y / imageRect.height,
        }

        setCoordinates(proportionalCoordinates)

        onPinpointChanged(proportionalCoordinates)
        selectComponent(proportionalCoordinates)
    }

    const selectComponent = (coord: Coord) => {
        const comps = productDetails
            .sort((a, b) => {
                return (a.x2 - a.x1) * (a.y2 - a.y1) - (b.x2 - b.x1) * (b.y2 - b.y1)
            })
            .filter((c) => c.x1 <= coord.x && coord.x <= c.x2 && c.y1 <= coord.y && coord.y <= c.y2)
            .map((c) => c.name)
        setSelectedComponent(comps.join(', '))
    }

    const handleEvent = (e: any) => {
        if (e.type == 'touchend' || e.type == 'touchmove' || e.type == 'touchstart') {
            var evt = typeof e.originalEvent === 'undefined' ? e : e.originalEvent
            var touch = evt.touches[0] || evt.changedTouches[0]
            updateCoordinates({ x: touch.clientX, y: touch.clientY })
            e.preventDefault()
        } else if (e.type == 'mouseup') {
            updateCoordinates({ x: e.clientX, y: e.clientY })
        } else {
            return
        }
    }

    const updateImageSize = (e: any) => {
        if (imageRef.current == null) {
            return
        }

        setImageSize({
            width: imageRef.current.offsetWidth,
            height: imageRef.current.offsetHeight,
        })
    }

    React.useEffect(() => {
        window.addEventListener('resize', updateImageSize)
        window.addEventListener('pageshow', updateImageSize)
        window.addEventListener('focus', updateImageSize)
        window.addEventListener('visibilitychange', updateImageSize)

        if (imageRef.current == null) {
            return
        }
        imageRef.current.addEventListener('touchstart', handleEvent, { passive: false })
        imageRef.current.addEventListener('touchmove', handleEvent, { passive: false })
        imageRef.current.addEventListener('touchend', handleEvent, { passive: false })
        imageRef.current.addEventListener('mouseup', handleEvent, { passive: false })
        imageRef.current.addEventListener('load', updateImageSize, { passive: false })

        return () => {
            window.removeEventListener('resize', updateImageSize)
            window.removeEventListener('pageshow', updateImageSize)
            window.removeEventListener('focus', updateImageSize)
            window.removeEventListener('visibilitychange', updateImageSize)

            if (imageRef.current == null) {
                return
            }
            imageRef.current.removeEventListener('touchstart', handleEvent)
            imageRef.current.removeEventListener('touchmove', handleEvent)
            imageRef.current.removeEventListener('touchend', handleEvent)
            imageRef.current.removeEventListener('mouseup', handleEvent)
            imageRef.current.removeEventListener('load', updateImageSize)
        }
    }, [imageRef.current])

    function hexToLottieRgb(hex: string) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
        return result
            ? {
                  r: parseInt(result[1], 16) / 255,
                  g: parseInt(result[2], 16) / 255,
                  b: parseInt(result[3], 16) / 255,
              }
            : null
    }

    const pinpointSet = coordinates.x >= 0

    return (
        <>
            <ImageWrapper
                style={{
                    width: imageSize.width > 0 ? imageSize.width + 'px' : '100%',
                    height: imageSize.height > 0 ? imageSize.height + 'px' : '100%',
                }}
            >
                <ProductImage
                    data-testid="pinpoint-image"
                    ref={imageRef}
                    onLoad={updateImageSize}
                    src={productImageUrl}
                    key={productImageUrl}
                    sx={{ opacity: pinpointSet ? 1 : 0.4 }}
                />
                <PinpointNudge
                    animationData={clickAnimation(hexToLottieRgb(theme.palette.primary.main)!)}
                    style={{
                        display: !imageRef.current || pinpointSet ? 'none' : 'block',
                    }}
                />
                <Crosshair
                    style={{
                        display: imageRef.current ? 'block' : 'none',
                        left: coordinates.x * imageSize.width + 'px',
                        top: coordinates.y * imageSize.height + 'px',
                        opacity: pinpointSet ? 1 : 0,
                        fill:
                            theme.palette.getCrosshairColors?.().fill ?? theme.palette.primary.main,
                        stroke:
                            theme.palette.getCrosshairColors?.().stroke ??
                            theme.palette.primary.main,
                    }}
                />
                {/* productDetails.map((c) => (
                    <div
                        key={c.name + c.x1 + c.x2 + c.y1 + c.y2}
                        style={{
                            position: 'absolute',
                            top: c.y1 * 100 + '%',
                            left: c.x1 * 100 + '%',
                            height: (c.y2 - c.y1) * 100 + '%',
                            width: (c.x2 - c.x1) * 100 + '%',
                            border: '1px solid red',
                            pointerEvents: 'none',
                            fontSize: '.25em',
                        }}
                    >
                        {c.name}
                    </div>
                )) */}
            </ImageWrapper>
        </>
    )
}

export default Pinpoint
