import React from 'react'
import TextField from '@mui/material/TextField'
import { SupportMultiStepFormContext } from '../context'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../shared-steps/components/step-framework-wrapper'
import { useTranslation } from 'react-i18next'
import { useCopilotChat } from '@copilotkit/react-core'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export const DescriptionStep = ({
    onDescriptionChange,
    initialDescription,
}: {
    onDescriptionChange: (description: string) => void
    initialDescription?: string
}) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle i18nKey="poc.support.descriptionStep.title" mb={4} />
            <TextField
                multiline
                fullWidth
                defaultValue={initialDescription}
                label={t('poc.support.descriptionStep.label' as any)}
                placeholder={t('poc.support.descriptionStep.placeholder' as any)}
                minRows={5}
                maxRows={15}
                onChange={(e) => onDescriptionChange(e.target.value)}
            ></TextField>
        </>
    )
}

const DescriptionStepContext = () => {
    const { form, ctx, props } = React.useContext(SupportMultiStepFormContext)
    const [isLoading, setIsloading] = React.useState(false)
    const { append } = useCopilotChat({})

    return (
        <StepFrameworkWrapper
            navigationProps={{
                nextButtonDisabled: !form.formValues.description,
                onValidNextClick: async () => {
                    setIsloading(true)
                    try {
                        if (
                            form.formValues.description?.toLowerCase().includes('water') ||
                            form.formValues.description?.toLowerCase().includes('wasser')
                        ) {
                            form.setClaimProbability(80)
                            ctx.removeNoWarrantySteps()
                        } else {
                            form.setClaimProbability(40)
                            ctx.addNoWarrantySteps()
                        }

                        const resultRelated = append({
                            id: Math.random() + '',
                            role: 'user',
                            content: `
                            The user has the following issue: "${form.formValues.description} ${
                                form.formValues.selectedComponent
                                    ? `regarding the component "${form.formValues.selectedComponent}"`
                                    : ''
                            }".
                            Which support services are closely related and might be relevant to the user?`,
                        })
                        await Promise.all([resultRelated])
                    } catch (error) {
                        setIsloading(false)
                        console.error(error)
                    } finally {
                        setIsloading(false)
                        props.nextSlide()
                    }
                },
            }}
        >
            <DescriptionStep
                onDescriptionChange={form.setDescription}
                initialDescription={form.formValues.description}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </StepFrameworkWrapper>
    )
}

export default DescriptionStepContext
