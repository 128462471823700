import useEnv, { Env } from './mixins/use-env'
const appV = import.meta.env.VITE_APP_VERSION

const { environment, isLocal } = useEnv()

const rollbarConfig = {
    accessToken: '956c8cbfdb58442e96d51fdae4d76680',
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: !isLocal,
    payload: {
        environment: environment,
        client: {
            javascript: {
                source_map_enabled: true,
                code_version: appV,
            },
        },
    },
}

export default rollbarConfig
