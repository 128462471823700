import React from 'react'
import Typography from '@mui/material/Typography'
import styled, { keyframes } from 'styled-components'
import BrushStroke from '../../../../assets/img/brush-stroke.png'
import { InspectorMultiStepFormContext } from '../inspector-multi-step-form'
import theme from '../../../../theme'
import routeBuilder from '../../../../routes'
import T from '../../../../components/typography/t'

const strike = keyframes`
from {
    clip-path: inset(0 100% 0 0);
}
to {
    clip-path: inset(0 0 0 0);
}
`

const PageContainer = styled.div`
    background-color: ${theme.palette.primary.main};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`

const ThankYouContainer = styled.div`
    position: relative;
`

const ThankYou = styled(T)`
    position: relative;
    z-index: 1;
`

const BrushStrokeImg = styled.img`
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.3);
    z-index: 0;
    animation: ${strike} 1s ease-in-out 0.3s forwards 1;
    clip-path: inset(0 100% 0 0);
`

const Outro = () => {
    const brushStrokeRef = React.useRef<HTMLImageElement>(null)
    const { ctx } = React.useContext(InspectorMultiStepFormContext)

    const onAnimationDone = () => {
        setTimeout(() => {
            ctx.postInspectionAction()
        }, 500)
    }

    React.useEffect(() => {
        if (brushStrokeRef.current) {
            brushStrokeRef.current.addEventListener('animationend', onAnimationDone)
        }
        return () => {
            if (brushStrokeRef.current) {
                brushStrokeRef.current.removeEventListener('animationend', onAnimationDone)
            }
        }
    }, [brushStrokeRef.current])

    return (
        <PageContainer>
            <ThankYouContainer>
                <BrushStrokeImg
                    src={BrushStroke}
                    ref={brushStrokeRef}
                    data-testid="outro-brush-stroke"
                />
                <ThankYou
                    i18nKey="inspector.mobile.outro.title"
                    variant="h4"
                    component="h1"
                    mx={2}
                    color={'primary'}
                />
            </ThankYouContainer>
        </PageContainer>
    )
}

export default Outro
