import React from 'react'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import LanguageSwitcher from '../language-switcher'
import whitelabels from './multi-step/data/whitelabel'
import { ThemeProvider, createTheme } from '@mui/material'
import {
    Page,
    Header,
    WidthContainer,
    BrandLogo,
    Content,
    Footer,
    PBB,
    PoweredBy,
    PoweredByBrakeable,
} from '../layout'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 16px;
    position: relative;
`

const Linktree = () => {
    return (
        <Page>
            <Header>
                <WidthContainer sx={{ justifyContent: 'space-between' }}>
                    <BrandLogo src="https://ucarecdn.com/9e0bb50d-8d9f-4779-ae66-f32c5b04d9ad/" />

                    <LanguageSwitcher />
                </WidthContainer>
            </Header>
            <Content>
                <WidthContainer>
                    <Container>
                        {whitelabels.map((entry, index) => (
                            <ThemeProvider
                                key={entry.id}
                                theme={(theme: any) =>
                                    createTheme({
                                        ...theme,
                                        palette: {
                                            ...theme.palette,
                                            ...entry?.theme.palette,
                                        },
                                    } as any)
                                }
                            >
                                <Button
                                    variant="contained"
                                    href={`/lostagnone?cb=${entry.id}`}
                                    fullWidth
                                    size="large"
                                    sx={{ mt: 2 }}
                                >
                                    {entry.name}
                                </Button>
                            </ThemeProvider>
                        ))}
                    </Container>
                </WidthContainer>
            </Content>
            <Footer>
                <WidthContainer sx={{ justifyContent: 'flex-end' }}>
                    <PBB>
                        <PoweredBy>Powered by</PoweredBy>{' '}
                        <PoweredByBrakeable>BRAKEABLE</PoweredByBrakeable>
                    </PBB>
                </WidthContainer>
            </Footer>
        </Page>
    )
}

export default Linktree
