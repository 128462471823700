import {
    useAction,
    createProduct,
    getProductsByCompany,
    type OptimisticUpdateDefinition,
} from 'wasp/client/operations'
import React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import { useForm } from 'react-hook-form'
import Dialog from '@mui/material/Dialog'
import T from '../../../../../components/typography/t'
import CompanyId from '../../../../../../shared/types/company/id'
import { useIsMobile } from '../../../../../mixins/media-query'
import SetupMultiStepForm, { FormValues } from './setup-multi-step-form'
import uploadcareClient from '../../../../../mixins/uploadcare-client'

const NewProductDialog: React.FC<{
    handleClose: () => void
    isOpen: boolean
    companyId?: CompanyId
}> = ({ handleClose, isOpen, companyId }) => {
    const isMobile = useIsMobile()
    const [isSubmitting, setIsSubmitting] = React.useState(false)

    const createProductOptimistically = useAction(createProduct, {
        optimisticUpdates: [
            {
                getQuerySpecifier: ({ companyId }) => [getProductsByCompany, { companyId }],
                updateQuery: (newItem, oldData) => {
                    return {
                        ...oldData,
                        products: [
                            ...oldData.products,
                            {
                                id: -1,
                                name: newItem.productName,
                                markers: [],
                                productImgUrl: newItem.productImgUrl,
                            },
                        ],
                    }
                },
            } as OptimisticUpdateDefinition<any, any>,
        ],
    })

    const close = React.useCallback(() => {
        handleClose()
    }, [])

    const onSubmit = async (data: FormValues) => {
        setIsSubmitting(true)
        await createProductOptimistically({
            productName: data.productName!,
            productImgUrl: data.useCroppedImage ? data.croppedImageUrl! : data.originalImageUrl!,
            companyId,
        })
        setIsSubmitting(false)
        close()
    }
    return (
        <Dialog open={isOpen} onClose={close} fullWidth maxWidth="md">
            <DialogTitle>
                <T i18nKey="company.inspector.home.products.newProduct.title" />
            </DialogTitle>
            <SetupMultiStepForm onSubmit={onSubmit} uploadCareClient={uploadcareClient} />
            {isSubmitting && 'Submitting...'}
        </Dialog>
    )
}

export default NewProductDialog
