import Box from '@mui/material/Box'
import styled from 'styled-components'
import T, { TProps } from './t'

const StepTitleWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const StepTitleIcon = styled.img`
    width: 80px;
    height: 80px;
`

const InspectorStepTitle = ({ children, Icon, mb, sx, ...props }: TProps & { Icon?: string }) => {
    return (
        <StepTitleWrapper mb={mb}>
            {Icon && <StepTitleIcon src={Icon} alt="" />}
            <T
                variant="h1"
                component="h1"
                color="primary"
                sx={{
                    textAlign: 'center',
                    ...sx,
                }}
                {...props}
            >
                {children}
            </T>
        </StepTitleWrapper>
    )
}

export default InspectorStepTitle
