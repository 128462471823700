import React from 'react'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import styled from 'styled-components'
import ProductDashboardT from '../../../../../shared/types/product/product-dashboard'
import SubmissionsViewer from '../../common/submissions-viewer'
import NoSubmissionsHelper from './no-submissions-helper'
import SubmissionId from '../../../../../shared/types/submission/id'
import KeyFacts from './key-facts'

const StyledCard = styled(Card)`
    width: 100%;
`

const Title = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const ProductDashboard = ({
    productDashboard,
    markSubmissionAsSeen,
}: {
    productDashboard: ProductDashboardT
    markSubmissionAsSeen: ({ submissionId }: { submissionId: SubmissionId }) => void
}) => {
    return (
        <>
            {!!productDashboard.keyFacts && (
                <KeyFacts keyFacts={productDashboard.keyFacts}></KeyFacts>
            )}
            <StyledCard>
                <CardContent>
                    <Title>
                        <Typography variant="h5" component="h2" mr={1}>
                            {productDashboard.name}
                        </Typography>
                    </Title>
                    {productDashboard.submissions.length > 0 ? (
                        <SubmissionsViewer
                            productDashboard={productDashboard}
                            markSubmissionAsSeen={markSubmissionAsSeen}
                        />
                    ) : (
                        <NoSubmissionsHelper
                            productImgUrl={productDashboard.productImgUrl}
                            inspectorUrl={productDashboard.inspectorUrl}
                        />
                    )}
                </CardContent>
            </StyledCard>
        </>
    )
}

export default ProductDashboard
