import Alert from '@mui/material/Alert'
import { OverridableComponent, OverridableTypeMap } from '@mui/material/OverridableComponent'
import styled from 'styled-components'
import theme from '../../theme'

const StyledWarningAlert: OverridableComponent<OverridableTypeMap> = styled(Alert)`
    border: 1px solid ${theme.palette.warning.dark}};
    display:flex;
    align-items: center;
`

export default StyledWarningAlert
