import { submitHappySubmissionFeedback } from 'wasp/client/operations'
import React from 'react'
import Button from '@mui/material/Button'
import styled from 'styled-components'
import { useAnalytics } from 'use-analytics'
import { InspectorMultiStepFormContext } from '../inspector-multi-step-form'
import T from '../../../../components/typography/t'
import ColabLogos from '../../../../components/colab-logos'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 16px;
    position: relative;
`

export const IntroStep: React.FC<{
    onStartClick: () => void
    companyLogoUrl: string
    onHappySubmissionFeedback: () => void
    allowHappySubmissions: boolean
}> = ({ companyLogoUrl, onStartClick, allowHappySubmissions, onHappySubmissionFeedback }) => {
    return (
        <Container>
            <ColabLogos companyLogoUrl={companyLogoUrl} />
            <T i18nKey="inspector.mobile.intro.title" variant="h4" component="p" mb={1} mt={3} />
            <T
                i18nKey="inspector.mobile.intro.description"
                variant="body2"
                component="p"
                mb={2}
                align="center"
            />
            <Button
                variant="contained"
                onClick={onStartClick}
                fullWidth
                size="large"
                data-testid="start-inspection-button"
            >
                <T i18nKey="inspector.mobile.intro.action" />
            </Button>
            {allowHappySubmissions && (
                <Button
                    variant="outlined"
                    onClick={onHappySubmissionFeedback}
                    data-testid="happy-submission-feedback-button"
                    fullWidth
                    size="large"
                    sx={{ mt: 4 }}
                >
                    <T i18nKey="inspector.mobile.intro.nothingToReport.action" />
                </Button>
            )}
        </Container>
    )
}

const Intro = () => {
    const analytics = useAnalytics()
    const { ctx, props } = React.useContext(InspectorMultiStepFormContext)
    const onHappySubmissionFeedback = async () => {
        const user = analytics.user()
        await submitHappySubmissionFeedback({
            questionaireId: ctx.questionaireId,
            anonymousUserId: user.anonymousId,
        })
        props.gotoEnd()
    }

    return (
        <IntroStep
            onStartClick={props.nextSlide}
            companyLogoUrl={ctx.companyLogo}
            onHappySubmissionFeedback={onHappySubmissionFeedback}
            allowHappySubmissions={ctx.allowHappySubmissions}
        />
    )
}

export default Intro
