import React from 'react'
import { SupportMultiStepFormContext } from '../context'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../shared-steps//components/step-framework-wrapper'
import { useTranslation } from 'react-i18next'
import {
    Box,
    Stack,
    Button,
    IconButton,
    styled,
    Typography,
    Checkbox,
    FormControlLabel,
    Drawer,
} from '@mui/material'
import T from '../../../../../components/typography/t'
import DIYStep from './diy-step'
import { type SupportService } from '../data/support-services'
import { useForm } from 'react-hook-form'
import useDialog from '../../../../../mixins/use-dialog'
import { useIsDesktop } from '../../../../../mixins/media-query'
import CloseIcon from '@mui/icons-material/Close'
import InputField from '../../../../../components/form/input-field'

const LinkTreeEntry = styled('button')`
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
    padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(4)};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;

    &:not(:disabled):hover {
        background-color: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.primary.contrastText};
    }

    &:disabled {
        border-color: ${({ theme }) => theme.palette.grey[200]};
        color: ${({ theme }) => theme.palette.grey[200]};
    }
`

const Delimitter = styled('div')(
    ({ theme }) => `
    width: 100%;
    border-bottom: 1px solid ${theme.palette.primary.main};
`
)

const InnerDrawer = styled('div')`
    padding: ${({ theme }) => theme.spacing(4, 4)};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1em;
`

const DetailsCard = styled('div')`
    padding: ${({ theme }) => theme.spacing(4, 4)};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1em;
    box-shadow: ${({ theme }) => theme.shadows[4]};
    border-radius: ${({ theme }) => theme.spacing(1)};
`

const Linktree: React.FC<{
    onDiyClick: () => void
    onServicesClick: () => void
    repairGuideCount: number
    costEstimation?: number
}> = ({ onDiyClick, onServicesClick, repairGuideCount }) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle i18nKey="poc.support.solutionStep.linktree.title" mb={4} />
            <Stack gap={2} sx={{ width: '100%', justifyContent: 'center' }}>
                <LinkTreeEntry onClick={onDiyClick} disabled={repairGuideCount === 0}>
                    <T variant="h3" mb={1} i18nKey="poc.support.solutionStep.linktree.diyTitle" />
                    <T variant="body1" i18nKey="poc.support.solutionStep.linktree.diySubtitle" />
                </LinkTreeEntry>
                <LinkTreeEntry onClick={onServicesClick}>
                    <T
                        variant="h3"
                        mb={1}
                        i18nKey="poc.support.solutionStep.linktree.servicesTitle"
                    />
                    <T
                        variant="body1"
                        i18nKey="poc.support.solutionStep.linktree.servicesSubtitle"
                    />
                </LinkTreeEntry>
            </Stack>
        </>
    )
}

const CostEstimation: React.FC<{
    selectedServices: SupportService[]
    onDiyClick: () => void
    repairGuideCount: number
    onSubmit: (data: FormData) => void
    onAddMoreDefectsClick: () => void
}> = ({ selectedServices, onDiyClick, repairGuideCount, onSubmit, onAddMoreDefectsClick }) => {
    const isDesktop = useIsDesktop()
    const { isOpen, close, open } = useDialog()
    const maxPrice = Math.max(...selectedServices.map((service) => service.price))
    const minPrice = Math.min(...selectedServices.map((service) => service.price))
    const isPriceRange = maxPrice - minPrice > 0

    const dynamicModalChildren = (
        <>
            <IconButton
                onClick={(e: any) => {
                    e.target.blur()
                    close()
                }}
                sx={{
                    position: 'absolute',
                    top: (theme) => theme.spacing(1),
                    right: (theme) => theme.spacing(1),
                }}
            >
                <CloseIcon />
            </IconButton>
            <T
                variant="h3"
                fontWeight={500}
                color="primary"
                i18nKey="poc.support.contactStep.form.title"
            />
            <ContactForm onSubmit={onSubmit} onAddMoreDefectsClick={onAddMoreDefectsClick} />
        </>
    )

    return (
        <>
            <InspectorStepTitle i18nKey="poc.support.costEstimationStep.title" mb={4} />
            <Stack
                direction="row"
                gap={8}
                justifyContent="center"
                width="100%"
                alignItems="flex-start"
            >
                <Box
                    sx={{
                        maxWidth: (theme) =>
                            isDesktop ? `calc(50% - ${theme.spacing(4)})` : '100%',
                    }}
                >
                    <T
                        i18nKey="poc.support.costEstimationStep.costRange.title"
                        component="h2"
                        variant="h3"
                        color="primary"
                        mb={1}
                    />
                    <T
                        i18nKey="poc.support.costEstimationStep.costRange.subtitle"
                        component="p"
                        variant="body1"
                        mb={2}
                    />
                    <Typography variant="h2" color="primary">
                        {isPriceRange ? `${minPrice} - ${maxPrice}€` : `${maxPrice}€`}
                    </Typography>
                    <Delimitter sx={{ my: 4 }} />
                    <T
                        i18nKey="poc.support.costEstimationStep.assignedServices.title"
                        component="h2"
                        variant="h3"
                        color="primary"
                        mb={1}
                    />
                    <T
                        i18nKey="poc.support.costEstimationStep.assignedServices.subtitle"
                        component="p"
                        variant="body1"
                        mb={5}
                    />
                    <Stack gap={2} mb={4}>
                        {selectedServices.map((s) => (
                            <Stack direction="row" justifyContent="space-between" key={s.id}>
                                <Typography>{s.title}</Typography>
                                <Typography fontWeight={500}>{s.price}€</Typography>
                            </Stack>
                        ))}
                    </Stack>
                    {!isOpen && (
                        <Stack
                            direction={{ md: 'row', sm: 'column' }}
                            mt={2}
                            gap={2}
                            justifyContent={{ md: 'flex-end', xs: 'stretch' }}
                            width="100%"
                        >
                            {repairGuideCount > 0 && (
                                <Button onClick={onDiyClick}>
                                    <T i18nKey="poc.support.costEstimationStep.diyAction" />
                                </Button>
                            )}
                            <Button variant="contained" onClick={open}>
                                <T i18nKey="poc.support.costEstimationStep.repairAction" />
                            </Button>
                        </Stack>
                    )}
                </Box>
                {!isDesktop ? (
                    <Drawer
                        open={isOpen}
                        onClose={close}
                        PaperProps={{ sx: { borderRadius: '24px 24px 0 0' } }}
                    >
                        <InnerDrawer>{dynamicModalChildren}</InnerDrawer>
                    </Drawer>
                ) : (
                    isOpen && <DetailsCard>{dynamicModalChildren}</DetailsCard>
                )}
            </Stack>
        </>
    )
}

type FormData = {
    email: string
    maxRepairCost: number
    acceptTerms: boolean
}

const ContactForm: React.FC<{
    onSubmit: (data: FormData) => void
    onAddMoreDefectsClick: () => void
}> = ({ onSubmit, onAddMoreDefectsClick }) => {
    const [t] = useTranslation()
    const { register, watch, handleSubmit } = useForm<FormData>()

    return (
        <>
            <InputField
                id="email"
                fullWidth
                {...register('email')}
                label={t('poc.support.contactStep.form.emailLabel' as any)}
                sx={{ mb: 2 }}
            />
            <InputField
                id="max-repair-cost"
                fullWidth
                {...register('maxRepairCost')}
                label={t('poc.support.contactStep.form.maxRepairCostLabel' as any)}
                type="number"
                sx={{ mb: 2 }}
            />

            <FormControlLabel
                control={<Checkbox {...register('acceptTerms')} />}
                label={t('poc.support.contactStep.form.acceptTermsLabel' as any)}
                sx={{ mb: 4 }}
            />
            <Stack
                direction={{ sm: 'column', md: 'row' }}
                gap={2}
                justifyContent="space-between"
                sx={{ width: '100%', flexWrap: 'wrap' }}
            >
                <Button onClick={onAddMoreDefectsClick}>
                    <T i18nKey="poc.support.contactStep.form.addMoreDefects" />
                </Button>
                <Button onClick={handleSubmit(onSubmit)} variant="contained">
                    <T i18nKey="poc.support.contactStep.form.submit" />
                </Button>
            </Stack>
        </>
    )
}

const NoEstimationScreen: React.FC<{
    onSubmit: (data: FormData) => void
    onAddMoreDefectsClick: () => void
}> = ({ onSubmit, onAddMoreDefectsClick }) => {
    return (
        <>
            <InspectorStepTitle i18nKey="poc.support.contactStep.title" mb={4} />
            <ContactForm onSubmit={onSubmit} onAddMoreDefectsClick={onAddMoreDefectsClick} />
        </>
    )
}

const SolutionStepContext = () => {
    const { form, props, ctx } = React.useContext(SupportMultiStepFormContext)
    const repairGuides = ctx.repairGuides.filter((repairGuide) =>
        form.formValues.aiSelectedRepairGuides.includes(repairGuide.id)
    )
    const selectedRepairServices = ctx.supportServices.filter((s) =>
        form.formValues.aiSelectedServices.includes(s.id)
    )
    const [selectedApproach, setSelectedApproach] = React.useState(repairGuides.length > 0 ? 0 : 2)
    const onRepairFormSubmit = () => {
        props.nextSlide()
    }

    const addMoreDefectsClick = () => {
        form.resetForm()
        props.setSlide(0)
    }

    return (
        <StepFrameworkWrapper
            wide
            navigationProps={{
                nextButtonDisabled: !form.formValues.customerEmail,
                onValidPrevClick: () => {
                    if (selectedApproach === 0) {
                        props.prevSlide()
                    } else {
                        setSelectedApproach(0)
                    }
                },
            }}
        >
            {selectedApproach === 0 && (
                <Linktree
                    onDiyClick={() => setSelectedApproach(1)}
                    repairGuideCount={repairGuides.length}
                    costEstimation={100}
                    onServicesClick={() => setSelectedApproach(2)}
                />
            )}
            {selectedApproach === 1 && (
                <DIYStep
                    repairGuides={repairGuides}
                    brandId={ctx.whitelabel?.id}
                    onServicesClick={() => setSelectedApproach(2)}
                />
            )}
            {selectedApproach === 2 &&
                (selectedRepairServices.length > 0 ? (
                    <CostEstimation
                        selectedServices={selectedRepairServices}
                        onDiyClick={() => setSelectedApproach(1)}
                        repairGuideCount={repairGuides.length}
                        onSubmit={onRepairFormSubmit}
                        onAddMoreDefectsClick={addMoreDefectsClick}
                    />
                ) : (
                    <NoEstimationScreen
                        onSubmit={onRepairFormSubmit}
                        onAddMoreDefectsClick={addMoreDefectsClick}
                    />
                ))}
        </StepFrameworkWrapper>
    )
}

export default SolutionStepContext
