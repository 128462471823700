import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import styled from 'styled-components'
import Submission from '../../../../../../../shared/types/product/dashboard-submission'
import SubmissionList from '../submission-list'
import ImageMarker from '../../../../../../components/image-marker'
import createUploadcareImageSrc from '../../../../../../mixins/create-uploadcare-image-src'
import SubmissionId from '../../../../../../../shared/types/submission/id'
import TabPanel from '../../../../../../components/tabs/tab-panel'
import MobileInspectionImageGrid from './mobile-inspection-image-grid'

const ProductImage = styled.div`
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const MobileDashboard = ({
    productImgUrl,
    submissions,
    openSubmission,
}: {
    productImgUrl: string
    submissions: Array<Submission>
    openSubmission: (submissionId: SubmissionId) => void
}) => {
    const [value, setValue] = React.useState(0)
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <>
            <Tabs value={value} onChange={handleChange} aria-label="product-dashboard-tabs">
                <Tab label="Heatmap" id="heatmap-tab" aria-controls="heatmap-panel" />
                <Tab label="Photos" id="photos-tab" aria-controls="photos-panel" />
                <Tab
                    label="Descriptions"
                    id="descriptions-tab"
                    aria-controls="descriptions-panel"
                />
            </Tabs>
            <TabPanel name="heatmap" open={value === 0}>
                <ProductImage>
                    <ImageMarker
                        imageUrl={createUploadcareImageSrc(productImgUrl, {
                            preview: '800x800',
                        })}
                        highlightSubmission={() => {}}
                        openSubmission={openSubmission}
                        markers={submissions.map((sub) => ({
                            id: sub.id,
                            x: sub.imagePinpoint.x,
                            y: sub.imagePinpoint.y,
                        }))}
                    />
                </ProductImage>
            </TabPanel>
            <TabPanel name="photos" open={value === 1}>
                <MobileInspectionImageGrid
                    submissions={submissions}
                    openSubmission={openSubmission}
                />
            </TabPanel>
            <TabPanel name="descriptions" open={value === 2}>
                <SubmissionList
                    submissions={submissions}
                    highlightSubmission={() => {}}
                    openSubmission={openSubmission}
                />
            </TabPanel>
        </>
    )
}
export default MobileDashboard
