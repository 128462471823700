import React from 'react'
import { requestReminderEmail } from 'wasp/client/operations'
import styled from 'styled-components'
import { InspectorMultiStepFormContext } from '../../inspector-multi-step-form'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../components/step-framework-wrapper'
import createUploadcareImageSrc from '../../../../../mixins/create-uploadcare-image-src'
import LoadingSkeleton from '../../../../../components/loading/skeleton'
import InspectorStepSubtitle from '../../../../../components/typography/inspector-step-subtitle'
import T from '../../../../../components/typography/t'
import PhotoInput from './photo-input'
import Button from '@mui/material/Button'
import RemindMe, { RemindMeFormData } from './remind-me'
import useDialog from '../../../../../mixins/use-dialog'
import { useTranslation } from 'react-i18next'
import { UploadClient } from '@uploadcare/upload-client'

const PreviewImgBox = styled.div`
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const PreviewImg = styled.img`
    max-width: 100%;
    max-height: 100%;
`

export const PhotoStep = ({
    onPhotoChosen,
    setInspectionImageUrl,
    inspectionImageUrl,
    onPhotoUploadFailed,
    setUploadProgress,
    uploadProgress,
    allowRemindMeLater,
    allowGalleryUpload,
    onRemindMeLaterSubmit,
    uploadCareClient,
}: {
    onPhotoChosen: () => void
    onPhotoUploadFailed: (msg: string) => void
    inspectionImageUrl?: string
    setInspectionImageUrl: (url?: string) => void
    setUploadProgress: (progression: number) => void
    uploadProgress?: number
    allowRemindMeLater: boolean
    allowGalleryUpload: boolean
    onRemindMeLaterSubmit: (formData: RemindMeFormData) => void
    uploadCareClient: UploadClient
}) => {
    const onProgress = ({ isComputable, value }: any) => {
        if (isComputable) {
            setUploadProgress(Math.min(value, 0.99))
        }
    }

    const {
        open: openRemindMeOverlay,
        close: closeRemindMeOverlay,
        isOpen: remindMeOverlayIsOpen,
    } = useDialog()

    const chosePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
        const photo = e.target.files?.[0]
        if (!photo) {
            return
        }
        setUploadProgress(0)
        setInspectionImageUrl(undefined)
        uploadCareClient
            .uploadFile(photo, { onProgress })
            .then((file) => {
                if (file.cdnUrl === null) {
                    onPhotoUploadFailed('Uploadcare returned null CDN URL')
                } else {
                    setInspectionImageUrl(file.cdnUrl!)
                    setUploadProgress(1)
                }
            })
            .catch(onPhotoUploadFailed)
        onPhotoChosen()
    }

    return (
        <>
            <InspectorStepTitle mb={2} i18nKey="inspector.mobile.photo.title" />
            <InspectorStepSubtitle i18nKey="inspector.mobile.photo.description" />
            <PhotoInput chosePhoto={chosePhoto} allowGalleryUpload={allowGalleryUpload}>
                {!!inspectionImageUrl ? (
                    <T i18nKey="inspector.mobile.photo.reAction" />
                ) : (
                    <T i18nKey="inspector.mobile.photo.action" />
                )}
            </PhotoInput>
            {!!inspectionImageUrl && !!uploadProgress && (
                <>
                    <T
                        i18nKey="inspector.mobile.photo.preview"
                        textAlign="center"
                        variant="body1"
                        mt={3}
                        mb={1}
                    />
                    <PreviewImgBox>
                        {uploadProgress === 1 ? (
                            <PreviewImg
                                src={createUploadcareImageSrc(inspectionImageUrl, {
                                    preview: '380x600',
                                })}
                            />
                        ) : (
                            <LoadingSkeleton height="100%" sx={{ maxWidth: '100%' }} />
                        )}
                    </PreviewImgBox>
                </>
            )}
            {allowRemindMeLater && !inspectionImageUrl && (
                <Button
                    onClick={openRemindMeOverlay}
                    variant="outlined"
                    sx={{ position: 'absolute', bottom: '100px' }}
                    data-testid="remind-me-later-button"
                >
                    <T i18nKey="inspector.mobile.photo.itemNotAvailable" />
                </Button>
            )}
            <RemindMe
                close={closeRemindMeOverlay}
                isOpen={remindMeOverlayIsOpen}
                handleSubmit={onRemindMeLaterSubmit}
            />
        </>
    )
}

const PhotoStepContext = () => {
    const { i18n } = useTranslation()
    const { props, form, meta, error, ctx, uploadCareClient } = React.useContext(
        InspectorMultiStepFormContext
    )
    const onPhotoChosen = () => {
        props.nextSlide()
    }

    const onPhotoUploadFailed = (err: any) => {
        console.error(err)
        meta.setMetaValue({ ...meta, fileUpload: { progress: 0 } })
        error.pushError({
            message: 'Foto konnte nicht hochgeladen werden',
            action: {
                label: 'Erneut versuchen',
                onClick: () => {
                    error.popError()
                    props.setSlide(2)
                },
            },
        })
    }

    const setUploadProgress = (progression: number) => {
        meta.setMetaValue({ ...meta, fileUpload: { progress: progression } })
    }

    const onRemindMeLaterSubmit = async (formData: RemindMeFormData) => {
        await requestReminderEmail({
            datetime: formData.datetime.toISOString(),
            email: formData.email,
            questionaireUrl: location.href,
            companyName: ctx.companyName,
            productName: ctx.productName,
            lang: i18n.language,
        })
        props.gotoEnd()
    }

    return (
        <StepFrameworkWrapper
            navigationProps={{
                nextButtonDisabled:
                    !form.formValues.inspectionImageUrl && !meta.value?.fileUpload?.progress,
            }}
        >
            <PhotoStep
                onPhotoChosen={onPhotoChosen}
                inspectionImageUrl={form.formValues.inspectionImageUrl}
                setInspectionImageUrl={form.setInspectionImageUrl}
                allowRemindMeLater={ctx.allowRemindMeLater}
                allowGalleryUpload={ctx.allowGalleryUpload}
                onPhotoUploadFailed={onPhotoUploadFailed}
                setUploadProgress={setUploadProgress}
                uploadProgress={meta.value?.fileUpload?.progress}
                onRemindMeLaterSubmit={onRemindMeLaterSubmit}
                uploadCareClient={uploadCareClient}
            />
        </StepFrameworkWrapper>
    )
}

export default PhotoStepContext
