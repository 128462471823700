import React from 'react'
import { SupportMultiStepFormContext } from '../context'
import useFakeFeatures from '../helper/use-fake-features'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from './components/step-framework-wrapper'
import InspectorStepSubtitle from '../../../../../components/typography/inspector-step-subtitle'
import { useTranslation } from 'react-i18next'
import { ProductCategoryNode } from '../data/products/types'
import { isProductCategory, findParentById, isProductType } from '../data/products/helpers'
import { styled, useTheme } from '@mui/material'
import T from '../../../../../components/typography/t'
import { useForm } from 'react-hook-form'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import InputField from '../../../../../components/form/input-field'

const Wrapper = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    ${({ theme }) => theme.breakpoints.down('md')} {
        grid-template-columns: 1fr 1fr;
    }
`

const Grid = styled('div')`
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: 1fr;
    grid-template-columns: calc(25% - 6px) calc(25% - 6px) calc(25% - 6px) calc(25% - 6px);
    gap: 8px 8px;

    ${({ theme }) => theme.breakpoints.down('md')} {
        grid-template-columns: calc(50% - 4px) calc(50% - 4px);
    }
`

const CategoryTile = styled('a')<{ $deactivated: boolean }>`
    padding: 0.75em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    gap: 10px;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    font-weight: 500;
    font-size: 1.2em;
    transition: background-color 0.2s, box-shadow 0.2s;
    ${({ theme, $deactivated }) =>
        $deactivated
            ? `
            border-color: ${theme.palette.grey[100]};
            color: ${theme.palette.grey[100]};
        `
            : `
        cursor: pointer;
        
        &:hover {
            background: ${theme.palette.grey[50]};
        }
        `}
`

const LabelledDelimiter = styled('div')`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 0;
        top: 50%;
        left: 0;
        border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
        z-index: -1;
    }
`

const DelimiterLabel = styled(T)`
    color: ${({ theme }) => theme.palette.grey[200]};
    padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
    background: #fff;
    width: auto;
    text-transform: uppercase;
`

type BreadcrumbProps = {
    currentNode: ProductCategoryNode
    onSelect: (node: ProductCategoryNode) => void
    productCategories: ProductCategoryNode
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ currentNode, onSelect, productCategories }) => {
    const theme = useTheme()
    // Helper function to find the parent path to the current node
    const findParentPath = (
        node: ProductCategoryNode,
        root: ProductCategoryNode,
        path: ProductCategoryNode[] = []
    ): ProductCategoryNode[] | null => {
        // If the current node is the root, return the path
        if (node === root) {
            return path
        }

        // If the current node is a ProductCategory, check its subItems
        if ('subItems' in root) {
            for (const subItem of root.subItems) {
                // If the subItem is the current node, return the path
                if (subItem === node) {
                    return [...path, root] // Add the parent to the path
                }

                // Recursively search in subItems
                const result = findParentPath(node, subItem, [...path, root]) // Pass a copy of the path
                if (result) {
                    return result // Return the path if found
                }
            }
        }

        return null // Return null if not found
    }

    // Get the breadcrumb path
    const path = findParentPath(currentNode, productCategories) // Start from the root

    return (
        <nav aria-label="Breadcrumb">
            <ul
                style={{
                    listStyle: 'none',
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {path &&
                    path.map((node, index) => (
                        <li
                            key={node.id.toString()}
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <button
                                onClick={() => onSelect(node)}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    color: theme.palette.primary.main,
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                    margin: '0',
                                    fontSize: '.9em',
                                }}
                            >
                                <T i18nKey={node.name as any} />
                            </button>
                            {index < path.length && (
                                <ArrowForwardIosIcon
                                    fontSize="small"
                                    sx={{
                                        verticalAlign: 'middle',
                                        color: 'grey',
                                        margin: '0 .75em ',
                                        fontSize: '.9em',
                                    }}
                                />
                            )}
                        </li>
                    ))}
                {/* Render the current node without a link */}
                <li>
                    <span
                        style={{
                            fontWeight: '400',
                        }}
                    >
                        <T i18nKey={currentNode.name as any} />
                    </span>
                </li>
            </ul>
        </nav>
    )
}

type FormData = {
    productReference: string
}

export const CategorySelectionStep = React.forwardRef(
    (
        {
            onSelectedProductTypeId,
            setFakeProductReference,
            initialValue,
            productCategories,
        }: {
            onSelectedProductTypeId: (productId?: number) => void
            setFakeProductReference: (productReference?: string) => void
            initialValue?: ProductCategoryNode | null
            productCategories: ProductCategoryNode
        },
        ref
    ) => {
        const theme = useTheme()
        const showFakeFeatures = useFakeFeatures()
        const [t] = useTranslation()
        const { register, watch, handleSubmit } = useForm<FormData>()
        const [currentTreeEntry, setCurrentTreeEntry] = React.useState(
            initialValue ?? productCategories
        )
        const onTileClick = (item: ProductCategoryNode) => {
            if (isProductCategory(item)) {
                setCurrentTreeEntry(item)
            } else {
                onSelectedProductTypeId(item.id)
            }
        }

        const goBackInterceptor = (og: () => void) => {
            const parent = findParentById(currentTreeEntry!.id, productCategories)
            if (parent) {
                setCurrentTreeEntry(parent)
            } else {
                og()
            }
        }

        React.useImperativeHandle(ref, () => ({
            goBackInterceptor,
        }))

        const productReferenceEntered = !!watch('productReference')

        const fakeReferenceMatcher = () => {
            setFakeProductReference(watch('productReference'))
        }

        return (
            <>
                <InspectorStepTitle
                    i18nKey="poc.support.productStep.title"
                    Icon={undefined}
                    mb={2}
                />
                <InspectorStepSubtitle mb={4} i18nKey="poc.support.productStep.subtitle" />
                {showFakeFeatures && (
                    <>
                        <InputField
                            fullWidth
                            {...register('productReference', { onChange: fakeReferenceMatcher })}
                            label={t('poc.support.productStep.form.productReference' as any)}
                        />

                        <LabelledDelimiter sx={{ my: 4 }}>
                            <DelimiterLabel i18nKey="common.or" />
                        </LabelledDelimiter>
                    </>
                )}

                <Wrapper>
                    <Grid>
                        {isProductCategory(currentTreeEntry)
                            ? currentTreeEntry.subItems.map((ListItem) => {
                                  const isType = isProductType(ListItem)
                                  const deactivated =
                                      productReferenceEntered ||
                                      (isType && !(ListItem.views.length > 0) && !showFakeFeatures)
                                  return (
                                      <CategoryTile
                                          key={ListItem.id}
                                          onClick={() =>
                                              deactivated ? null : onTileClick(ListItem)
                                          }
                                          $deactivated={deactivated}
                                      >
                                          <ListItem.Icon
                                              fill={
                                                  deactivated
                                                      ? theme.palette.grey[100]
                                                      : theme.palette.primary.main
                                              }
                                              style={{ maxWidth: 102, maxHeight: 102 }}
                                          ></ListItem.Icon>
                                          <T i18nKey={ListItem.name as any} />
                                      </CategoryTile>
                                  )
                              })
                            : null}
                    </Grid>
                </Wrapper>
            </>
        )
    }
)

const CategorySelectionContext = () => {
    const childRef = React.useRef<any>()
    const { form, props, ctx } = React.useContext(SupportMultiStepFormContext)
    const selectedId = form.formValues.selectedProduct as any

    const productCategories = ctx.productCategoryTree

    const fakeProductReferenceMatcher = (reference?: string) => {
        if (!!reference) {
            form.setSelectedProduct(111)
        } else {
            form.setSelectedProduct(undefined)
        }
    }

    return (
        <StepFrameworkWrapper
            wide
            navigationProps={{
                nextButtonDisabled: !selectedId,
                onValidPrevClick: () => {
                    childRef.current?.goBackInterceptor(props.prevSlide)
                },
            }}
        >
            <CategorySelectionStep
                ref={childRef}
                onSelectedProductTypeId={(id) => {
                    form.setSelectedProduct(id)
                    props.nextSlide()
                }}
                setFakeProductReference={fakeProductReferenceMatcher}
                initialValue={selectedId ? findParentById(selectedId, productCategories) : null}
                productCategories={productCategories}
            />
        </StepFrameworkWrapper>
    )
}

export default CategorySelectionContext
