import styled from 'styled-components'
import routeBuilder from '../../routes'
import createUploadcareImageSrc from '../../mixins/create-uploadcare-image-src'
import StepContainer from '../../pages/customer/inspector/steps/components/step-container'
import { StepContentContainer } from '../../pages/customer/inspector/steps/components/step-framework-wrapper'
import { DumbMultiStepNavigation } from '../../pages/customer/inspector/steps/components/navigation'
import { PinpointStep } from '../../pages/customer/inspector/steps/pinpoint-step'
import theme from '../../theme'

const DemoStepContainer = styled(StepContainer)`
    height: calc(100% - 40px);
    h1 {
        font-size: 1rem !important;
    }
    h2 {
        font-size: 0.5rem !important;
    }

    ${theme.breakpoints.down('sm')} {
        h1 {
            font-size: 0.48rem !important;
        }
        h2 {
            font-size: 0.3rem !important;
        }
    }
`

const DemoDumbMultiStepNavigationWrapper = styled.div`
    > div {
        height: 40px;
        font-size: 0.6em !important;
        ${theme.breakpoints.down('sm')} {
            font-size: 0.36rem !important;
        }
    }
`

const InspectorCustomerPreview = ({ imageUrl }: { imageUrl?: string }) => {
    const noop = () => {}
    const imgUrl = !!imageUrl
        ? createUploadcareImageSrc(imageUrl, { preview: '300x300' })
        : routeBuilder.productExampleImage()
    return (
        <DemoStepContainer>
            <StepContentContainer>
                <PinpointStep
                    productImageUrl={imgUrl}
                    setInspectionImagePinpoint={noop}
                    initialCoords={{
                        x: 0.4,
                        y: 0.4,
                    }}
                />
            </StepContentContainer>
            <DemoDumbMultiStepNavigationWrapper>
                <DumbMultiStepNavigation
                    currentStep={1}
                    maxSteps={8}
                    setSlide={noop}
                    nextSlide={noop}
                    prevSlide={noop}
                    gotoEnd={noop}
                    removeFollowUpStep={noop}
                    prevButtonDisabled={true}
                    prevButtonLabelKey="common.back"
                    nextButtonDisabled={true}
                    nextButtonLabelKey="common.next"
                />
            </DemoDumbMultiStepNavigationWrapper>
        </DemoStepContainer>
    )
}

export default InspectorCustomerPreview
