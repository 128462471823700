import React from 'react'
import { useTranslation } from 'react-i18next'
import T from '../../../components/typography/t'
import Button from '@mui/material/Button'
import InspectorStepTitle from '../../../components/typography/inspector-step-title'
import LanguageSwitcher from '../language-switcher'
import { Box, styled, Stack } from '@mui/material'
import { Whitelabel } from './multi-step/data/whitelabel'
import {
    Page,
    Header,
    WidthContainer,
    BrandLogo,
    Content,
    Footer,
    PBB,
    PoweredBy,
    PoweredByBrakeable,
    ProgressBar,
} from '../layout'

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`

const LinkTreeEntry = styled('button')`
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
    padding: ${({ theme }) => theme.spacing(3, 5)};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;

    &:not(:disabled):hover {
        background-color: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.primary.contrastText};
    }

    &:disabled {
        border-color: ${({ theme }) => theme.palette.grey[200]};
        color: ${({ theme }) => theme.palette.grey[200]};
    }
`

const Linktree = ({
    setType,
    whitelabel,
}: {
    setType: (type: number) => void
    whitelabel: Whitelabel
}) => {
    const [t] = useTranslation()
    return (
        <Page>
            <Header>
                <WidthContainer sx={{ justifyContent: 'space-between' }}>
                    <BrandLogo src={whitelabel.logo} alt={whitelabel.name} />
                    <LanguageSwitcher />
                </WidthContainer>
            </Header>
            <ProgressBar $progress={0} />
            <Content>
                <WidthContainer>
                    <Container>
                        <InspectorStepTitle
                            i18nKey="poc.support.linktree.title"
                            Icon={undefined}
                            mt={2}
                            mb={4}
                            px={4}
                        />

                        <Stack gap={3} sx={{ width: '100%' }} px={2}>
                            <LinkTreeEntry
                                onClick={() => setType(1)}
                                disabled={whitelabel.disabledMainFlows.includes(1)}
                            >
                                <T variant="h3" mb={1} i18nKey="poc.support.linktree.repair" />
                                <T variant="body1" i18nKey="poc.support.linktree.repairSub" />
                            </LinkTreeEntry>
                            <LinkTreeEntry
                                onClick={() => setType(2)}
                                disabled={whitelabel.disabledMainFlows.includes(2)}
                            >
                                <T variant="h3" mb={1} i18nKey="poc.support.linktree.warranty" />
                                <T variant="body1" i18nKey="poc.support.linktree.warrantySub" />
                            </LinkTreeEntry>
                            <LinkTreeEntry
                                onClick={() => setType(3)}
                                disabled={whitelabel.disabledMainFlows.includes(3)}
                            >
                                <T variant="h3" mb={1} i18nKey="poc.support.linktree.feedback" />
                                <T variant="body1" i18nKey="poc.support.linktree.feedbackSub" />
                            </LinkTreeEntry>
                            <LinkTreeEntry
                                onClick={() => setType(4)}
                                disabled={whitelabel.disabledMainFlows.includes(4)}
                            >
                                <T variant="h3" mb={1} i18nKey="poc.support.linktree.diy" />
                                <T variant="body1" i18nKey="poc.support.linktree.diySub" />
                            </LinkTreeEntry>
                            {whitelabel.linktreeLinks.map((link, index) => (
                                <LinkTreeEntry
                                    key={link.url}
                                    onClick={() => window.open(link.url, '_blank')?.focus()}
                                >
                                    {link.name}
                                </LinkTreeEntry>
                            ))}
                        </Stack>
                    </Container>
                </WidthContainer>
            </Content>
            <Footer>
                <WidthContainer sx={{ justifyContent: 'flex-end' }}>
                    <PBB>
                        <PoweredBy>Powered by</PoweredBy>{' '}
                        <PoweredByBrakeable>BRAKEABLE</PoweredByBrakeable>
                    </PBB>
                </WidthContainer>
            </Footer>
        </Page>
    )
}

export default Linktree
