import React from 'react'
import PageWrapper from '../../../../components/layout/page-wrapper'
import ProductDashboardWrapper from './product-dashboard-wrapper'
import AppSuite from '../../../../../shared/app-suite'
import CompanyId from '../../../../../shared/types/company/id'
import nullableParseInt from '../../../../mixins/nullable-parse-int'

const ProductDashboardPage = ({ match }: any) => {
    const { productId, companyId } = match.params
    const compId = nullableParseInt(companyId) as CompanyId | undefined
    const prodId = parseInt(productId)
    return (
        <PageWrapper activeLogo={AppSuite.Inspector} companyId={compId}>
            <ProductDashboardWrapper productId={prodId} />
        </PageWrapper>
    )
}

export default ProductDashboardPage
