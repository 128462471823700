import React from 'react'
import { SupportMultiStepFormContext } from '../context'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import StepFrameworkWrapper from '../shared-steps/components/step-framework-wrapper'
import InspectorStepSubtitle from '../../../../../components/typography/inspector-step-subtitle'
import { useTranslation } from 'react-i18next'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import createUploadcareImageSrc from '../../../../../mixins/create-uploadcare-image-src'
import ScrollWrapper from '../shared-steps/components/scroll-wrapper'

export const DIYStep = ({
    problemDescription,
    skipScreen,
}: {
    problemDescription: string
    skipScreen: () => void
}) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle i18nKey="poc.support.diyStep.title" Icon={undefined} />
            <InspectorStepSubtitle mb={2} i18nKey="poc.support.diyStep.subtitle" />
            <ScrollWrapper>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <ListItem alignItems="flex-start">
                        <ListItemButton
                            onClick={() => {
                                window
                                    .open(
                                        'https://de.ifixit.com/Anleitung/Wie+wasche+und+trockne+ich+meine+Vaude+Membran-Jacke--Hose-/69150',
                                        '_blank'
                                    )
                                    ?.focus()
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    alt="Remy Sharp"
                                    src={createUploadcareImageSrc(
                                        'https://ucarecdn.com/4b711ed6-e6ab-4ac7-ad60-09cc4e16ccc3/',
                                        { preview: '80x80' }
                                    )}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={t(
                                    'poc.support.diyStep.guides.howToRepairHole.title' as any
                                )}
                                secondary={t('poc.support.diyStep.guides.clickHere' as any)}
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </ScrollWrapper>
        </>
    )
}

const DIYStepContext = () => {
    const { props, form } = React.useContext(SupportMultiStepFormContext)

    return (
        <StepFrameworkWrapper
            navigationProps={{
                nextButtonDisabled: false,
                nextButtonLabelKey: 'poc.support.diyStep.skip',
            }}
        >
            <DIYStep
                problemDescription={form.formValues.description || ''}
                skipScreen={props.nextSlide}
            />
        </StepFrameworkWrapper>
    )
}

export default DIYStepContext
